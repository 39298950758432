import axios from 'axios'
import React from 'react'
import Formsy from 'formsy-react'
import { Link, Navigate } from 'react-router-dom'
import { Card, CardContent, Typography, Button, InputAdornment, Snackbar, Dialog } from '@mui/material'
import { Close, VpnKey, Email } from '@mui/icons-material'
import { C, D, Loading, TextFieldFormsy, openLink, obj2form } from './include'
import { MainPage } from './views/Main'
class Screen extends React.Component {
  render() {
    return (
      <D className="r2c e aF" style={{ backgroundColor: '#424242', padding: '30px' }}>
        <D className="e hide-sm" />
        <D className="col e aF" p="3">
          <D className="e hide-sm" />
          <D h="30px" />
          <D col p="2" img={C.coverImg} side />
          <Typography className="row w aC" variant="h3" color="white">{C.appName}</Typography>
          <D className="row w aC hv" onClick={() => openLink('http://ai2bizlab.com.br')}>
            <Typography variant="subtitle1" color="white"><b>Criado por AI2BIZ</b></Typography>
            <img style={{ marginLeft: '10px', height: '20px' }} src="/static/ai2_200.png" alt="logo" />
          </D>
          <D className="e hide-sm" />
        </D>
        <D className="e hide-sm" />
        <D className="col e aF" p="3">
          <D className="e hide-sm" />
          <D className="row aC">
            <Card className="row aF" square style={{ minWidth: '350px' }}>
              <CardContent className="col e aF">
                <D h="1rem" />
                {this.props.children}
                <D h="1rem" />
              </CardContent>
            </Card>
          </D>
          <D e />
        </D>
        <D className="e hide-sm" />
      </D>
    )
  }
}
class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: '', pwd: '' }
  }
  render() {
    return (
      <Screen>
        <Typography variant="h6" className="row w aC">Acesso</Typography>
        <D h="1rem" />
        <Formsy onValidSubmit={() => this.props.doLogin(this.state.email, this.state.pwd)} className="col w aF">
          <TextFieldFormsy type="text" name="email" label="Email" value={this.state.email} onChange={e => this.setState({ email: e })} required
            validations={{ minLength: 4 }}
            validationErrors={{ minLength: 'Muito curto' }}
            InputProps={{ endAdornment: <InputAdornment position="end"><Email color="action" /></InputAdornment> }}
          />
          <TextFieldFormsy type="password" name="password" label="Senha" value={this.state.pwd} onChange={e => this.setState({ pwd: e })} required
            validations={{ minLength: 4 }}
            validationErrors={{ minLength: 'Muita curta' }}
            InputProps={{ endAdornment: <InputAdornment position="end"><VpnKey color="action" /></InputAdornment> }}
          />
          <D h="1rem" />
          <D className="row w aC">
            <Button type="submit" variant="contained" color="primary" value="legacy">Entrar</Button>
          </D>
        </Formsy>
        <D h="1rem" />
        <D className="row w aC">
          <Link to="/forgot-password">
            <Typography variant="caption">Esqueceu a senha?</Typography>
          </Link>
        </D>
      </Screen>
    )
  }
}
export class NotFound extends React.Component {
  render() {
    return (
      <D className="row e aF fullH aF" style={{ backgroundColor: '#424242', padding: '30px' }}>
        <D e />
        <D className="col e aF" p="3">
          <D e h="3rem" />
          <D col p="2" img={C.coverImg} side />
          <D e h="3rem" />
          <Typography className="row w aC" variant="h5" color="white">Página não encontrada</Typography>
          <D e h="3rem" />
          <Typography className="row w aC" variant="h3" color="white">{C.appName}</Typography>
          <D className="row w aC hv" onClick={() => openLink('http://ai2bizlab.com.br')}>
            <Typography variant="subtitle1" color="white"><b>Criado por AI2BIZ</b></Typography>
            <img style={{ marginLeft: '10px', height: '20px' }} src="/static/ai2_200.png" alt="logo" />
          </D>
          <D e h="3rem" />
        </D>
        <D e />
      </D>
    )
  }
}
export class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.alert = this.alert.bind(this)
    this.state = {
      snackbar: false,
      snackbarMsg: '',
      email: window.location.pathname.split('/')[3],
      hash: window.location.pathname.split('/')[2],
      pwd: '',
      pwdConfirm: '',
      goToLogin: null
    }
  }
  componentDidMount = () => this.setState({
    pwd: '',
    pwdConfirm: ''
  })
  alert = m => this.setState({ snackbar: true, snackbarMsg: m })
  changePwd = () => {
    if (this.state.pwd === this.state.pwdConfirm) {
      axios.put(C.baseURL + 'updatePassword/' + this.state.hash + '/' + this.state.email, {
        password: this.state.pwd
      }, { timeout: 2000 }).then(r => {
        // if (r === 'ok') {
        this.alert('Senha alterada com sucesso')
        this.setState({ goToLogin: <Navigate replace to="/" /> })
        // }
        // document.getElementById('backToLoginFromChangePwd').click()
      }).catch(e => {
        this.alert('Não foi possível alterar a senha, link inválido')
        // document.getElementById('backToLoginFromChangePwd').click()
      })
    } else {
      this.alert('A senha e a confirmação precisam ser iguais')
    }
  }
  render() {
    return (
      <Screen>
        <Typography variant="h6" className="row w aC">Troca de senha</Typography>
        <D h="1rem" />
        <Formsy onValidSubmit={this.changePwd}
          onInvalidSubmit={() => this.alert('Preencha corretamente todos os campos!')}>
          <TextFieldFormsy type="text" name="email" defaultValue={this.state.email} value={this.state.email} label="Email" onChange={v => this.setState({ email: v })}
            validations="isEmail" validationErrors={{ isEmail: 'Email inválido' }}
            InputProps={{ endAdornment: <InputAdornment position="end"><Email /></InputAdornment> }} variant="outlined" required />
          <TextFieldFormsy type="password" name="password" label="Senha" defaultValue="" value={this.state.pwd} onChange={e => this.setState({ pwd: e })} required
            validations={{ minLength: 4 }}
            validationErrors={{ minLength: 'Muita curta' }}
            InputProps={{ endAdornment: <InputAdornment position="end"><VpnKey color="action" /></InputAdornment> }}
          />
          <TextFieldFormsy type="password" name="passwordConfirm" label="Senha" defaultValue="" value={this.state.pwdConfirm} onChange={e => this.setState({ pwdConfirm: e })} required
            validations={{ minLength: 4 }}
            validationErrors={{ minLength: 'Muita curta' }}
            InputProps={{ endAdornment: <InputAdornment position="end"><VpnKey color="action" /></InputAdornment> }}
          />
          <D className="row w aC">
            <Button type="submit" variant="contained" color="primary">Trocar senha</Button>
          </D>
        </Formsy>
        <D h="1rem" />
        <D className="row w aC">
          <Link to="/login" id="backToLoginFromChangePwd">
            <Typography variant="caption">Voltar para login</Typography>
          </Link>
        </D>
        {!!this.state.goToLogin && this.state.goToLogin}
        <Snackbar open={this.state.snackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000}
          action={<Close onClick={() => this.setState({ snackbar: false, snackbarMsg: '' })} />}
          message={this.state.snackbarMsg} onClose={() => this.setState({ snackbar: false, snackbarMsg: '' })} />
      </Screen>
    )
  }
}
export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.alert = this.alert.bind(this)
    this.changePwdRequest = this.changePwdRequest.bind(this)
    this.state = {
      email: '',
      snackbar: false,
      snackbarMsg: ''
    }
  }
  alert = m => this.setState({ snackbar: true, snackbarMsg: m })
  changePwdRequest = () => {
    axios.post(C.baseURL + 'forgotPassword', obj2form({ email: this.state.email })).then(r => {
      if (r.data.email === this.state.email) {
        this.alert('Enviamos um email com as orientações para a troca de senha')
      } else {
        this.alert('Não encontramos um usuário com este email')
      }
    }).catch(e => this.alert('Ocorreu um erro'))
  }
  render() {
    return (
      <Screen>
        <Typography variant="h6" className="row w aC">Recuperar minha senha</Typography>
        <D h="1rem" />
        <Formsy onValidSubmit={this.changePwdRequest}>
          <TextFieldFormsy type="text" name="email" value={this.state.email} label="Email" validations="isEmail" onChange={v => this.setState({ email: v })}
            validationErrors={{ isEmail: 'Email inválido' }} InputProps={{ endAdornment: <InputAdornment position="end"><Email /></InputAdornment> }} variant="outlined" required />
          <D h="1rem" />
          <D className="row w aC">
            <Button variant="contained" type="submit">Enviar</Button>
          </D>
        </Formsy>
        <D h="1rem" />
        <D className="row w aC">
          <Link to="/login">
            <Typography variant="caption">Voltar para login</Typography>
          </Link>
        </D>
        <Snackbar open={this.state.snackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000}
          action={<Close onClick={() => this.setState({ snackbar: false, snackbarMsg: '' })} />}
          message={this.state.snackbarMsg} onClose={() => this.setState({ snackbar: false, snackbarMsg: '' })} />
      </Screen>
    )
  }
}
export class Auth extends React.Component {
  constructor(props) {
    super(props)
    this.alert = this.alert.bind(this)
    this.dialog = this.dialog.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.setToken = this.setToken.bind(this)
    this.getToken = this.getToken.bind(this)
    this.updateAppData = this.updateAppData.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.signInWithToken = this.signInWithToken.bind(this)
    this.logout = this.logout.bind(this)
    this.login = this.login.bind(this)
    this.state = {
      authorized: false,
      snackbar: false,
      snackbarMsg: '',
      dialogOpen: false,
      dialogChildren: [],
      token: this.getToken(),
      loginScreen: <Loading style={{ backgroundColor: '#424242' }} />
    }
    this.api = axios.create({ baseURL: C.baseURL })
    this.api.interceptors.response.use(res => {
      if (res.data.status === 'NoApiAccess') {
        this.alert('Você não tem acesso a esta funcionalidade.')
        return {}
      }
      return res.data
    }, err => {
      if (!!err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
        this.alert('Sessão expirada, entre com seus dados de acesso.')
      } else {
        this.alert('Erro ao conectar com servidor')
      }
      return {}
    })
  }
  componentDidMount = () => this.handleAuthentication()
  alert = m => this.setState({ snackbar: true, snackbarMsg: m })
  dialog = m => this.setState({ dialogOpen: true, dialogChildren: m })
  dialogClose = () => this.setState({ dialogOpen: false, dialogChildren: [] })
  setToken = (t) => {
    if (!!t) {
      if (!!localStorage) {
        localStorage.setItem('jwt_access_token', t)
      }
      this.api.defaults.headers.common['Authorization'] = 'Bearer ' + t
      this.setState({ token: t })
    }
    else {
      localStorage.removeItem('jwt_access_token')
      delete this.api.defaults.headers.common['Authorization']
      this.setState({ token: '' })
    }
  }
  getToken = () => !!localStorage && (!!localStorage.getItem('jwt_access_token') ? localStorage.getItem('jwt_access_token') : '')
  handleAuthentication = () => {
    if (!!this.props.logout) {
      this.logout()
    } else {
      let tk = this.getToken()
      if (!!tk) {
        this.setToken(tk)
        this.signInWithToken()
      } else {
        this.logout()
      }
    }
  }
  updateAppData = (callback) => {
    this.api.post('/auth/access-token', { access_token: this.state.token }).then(r => {
      if (!!r.user) {
        this.setToken(r.access_token)
        this.api.get('/getRoles').then(p => {
          let permissions = []
          for (const [k, v] of Object.entries(p)) {
            v.forEach(rl => r.user.role === rl && permissions.push(k))
          }
          const u = { ...r.user, permissions }
          this.setState({
            loginScreen: <MainPage
              api={this.api}
              user={u}
              logout={this.logout}
              alert={this.alert}
              dialog={this.dialog}
              dialogClose={this.dialogClose}
              updateAppData={this.updateAppData} />
          }, () => typeof callback === 'function' ? callback() : null)
        })
      }
      else {
        this.logout()
      }
    }).catch(() => this.logout())
  }
  signInWithToken = () => this.updateAppData(() => this.alert('Sessão iniciada com sucesso'))
  login = (email, password) => {
    if (!!email && !!password) {
      this.api.post('/sessions', { email: email, password: password }).then(r => {
        if (!r.error && r.user.email === email) {
          this.setToken(r.access_token)
          this.api.get('/getRoles').then(p => {
            let permissions = []
            for (const [k, v] of Object.entries(p)) {
              v.forEach(rl => r.user.role === rl && permissions.push(k))
            }
            const u = { ...r.user, permissions }
            this.setState({
              loginScreen: <MainPage
                api={this.api}
                user={u}
                logout={this.logout}
                alert={this.alert}
                dialog={this.dialog}
                dialogClose={this.dialogClose}
                updateAppData={this.updateAppData} />
            })
            this.alert('Sessão iniciada com sucesso')
          }).catch(() => this.logout())
        } else {
          this.alert('Usuário ou senha incorretos!')
        }
      }).catch(() => this.logout())
    }
  }
  logout = () => {
    this.setToken('')
    this.setState({ authorized: false, loginScreen: !!this.props.logout ? <Navigate to="/" replace /> : <Login doLogin={(u, p) => this.login(u, p)} /> })
  }
  render() {
    return (
      <D className="row e aF scD">
        <>{this.state.loginScreen}</>
        <Dialog open={this.state.dialogOpen} onClose={this.dialogClose}>{this.state.dialogChildren}</Dialog>
        <Snackbar open={this.state.snackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={7000}
          action={<Close onClick={() => this.setState({ snackbar: false, snackbarMsg: '' })} />}
          message={this.state.snackbarMsg} onClose={() => this.setState({ snackbar: false, snackbarMsg: '' })} />
      </D>
    )
  }
}
