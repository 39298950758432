import React from 'react'
import { Table, TableContainer, TableHead, TableBody, TextField, Paper } from '@mui/material'
import { D, Loading, TRow, TCell, CustomSelect } from '../include'
import moment from 'moment'
import _ from 'underscore'
moment.locale('pt_br')
export class Logs extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = {
      dataini: new Date().toISOString().substring(0, 10),
      datafim: new Date().toISOString().substring(0, 10),
      selectedUserName: 'Todos',
      userNames: ['Todos'],
      dataTable: [],
      rows: [
        <TRow key={Math.random().toString()}>
          <TCell component="th" align="center" colSpan={6}>
            <Loading />
          </TCell>
        </TRow>
      ]
    }
  }
  componentDidMount = () => this.updateData({
    dataini: this.state.dataini,
    datafim: this.state.datafim
  })
  updateData = dts => this.props.api.get('/logs', { params: dts }).then(r => {
    this.setState(dts)
    this.updateTable(r.logs)
  })
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(
        <TRow key={Math.random().toString()} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TCell component="th">{row.username}</TCell>
          <TCell component="th">{row.url}</TCell>
          <TCell component="th">{moment(row.created_at).format('LLL')}</TCell>
        </TRow>
      )
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={10}>Nenhuma dado encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res, userNames: _.union(['Todos'], _.uniq(_.pluck(rows, 'username'))), selectedUserName: 'Todos' })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aC">
              <CustomSelect
                label="Usuário"
                heightSize="small"
                style={{ maxWidth: '25em' }}
                InputLabelProps={{ shrink: true }}
                value={this.state.selectedUserName}
                options={this.state.userNames}
                onChange={v => this.setState({ selectedUserName: v })}
              />
              <D e />
              <D size="1rem" />
              <TextField
                size="small"
                label="Data inicial"
                type="date"
                value={this.state.dataini}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.updateData({ dataini: e.target.value, datafim: this.state.datafim })} />
              <D size="1rem" />
              <TextField
                size="small"
                label="Data final"
                type="date"
                value={this.state.datafim}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.updateData({ dataini: this.state.dataini, datafim: e.target.value })} />
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow sx={{ height: '3rem', background: '#ccc' }}>
                  <TCell>Usuário</TCell>
                  <TCell>Caminho</TCell>
                  <TCell>Criado em</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.selectedUserName && this.state.selectedUserName !== 'Todos') {
                  if (r.props.row.username === this.state.selectedUserName) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
