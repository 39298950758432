import React from 'react'
import Formsy from 'formsy-react'
import { Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogActions, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { D, Loading, SearchField, TRow, TCell, TextFieldFormsy, CustomSelect, ConfirmDialog } from '../include'
class EditFact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tipo_de_dado: !!this.props.row ? this.props.row.tipo_de_dado : '',
      qualificator: !!this.props.row ? this.props.row.qualificator : '',
      gestao_de_alerta: !!this.props.row ? this.props.row.gerador_de_alerta : false,
    }
  }
  render() {
    return (
      <Formsy>
        {!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Adicionar Fato</DialogTitle>}
        {!!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Editar Fato</DialogTitle>}
        <DialogContent>
          <D h="1rem" />
          <TextFieldFormsy
            label="Qualificador"
            name="Qualificador"
            variant="outlined"
            defaultValue={!!this.props.row ? this.props.row.qualificator : ''}
            value={this.state.qualificator}
            onChange={v => this.setState({ qualificator: v })}
            required />
          <D h="1rem" />
          <CustomSelect
            label="Tipo"
            value={this.state.tipo_de_dado}
            options={['bool', 'date', 'float', 'int', 'string']}
            onChange={v => this.setState({ tipo_de_dado: v })}
            required />
          <D h="1rem" />
          <FormControl>
            <FormControlLabel
            labelPlacement="start"
            label="Gestão de Alerta"
            control={<Checkbox checked={this.state.gestao_de_alerta} value={this.state.gestao_de_alerta} onChange={e =>{this.setState({gestao_de_alerta: e.target.checked})}}/>}
            />
          </FormControl>
          <D h="2rem"  />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            if (!this.props.edit) {
              this.props.api.post('/Facts/', {
                tipo_de_dado: this.state.tipo_de_dado,
                qualificator: this.state.qualificator,
                gestao_de_alerta: this.state.gestao_de_alerta
              }).then(r => {
                this.props.dialogClose()
                this.props.alert('Registro criado com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao criar o registro'))
            } else {
              this.props.api.put('/Fact/' + this.props.row.id, {
                tipo_de_dado: this.state.tipo_de_dado,
                qualificator: this.state.qualificator,
                gestao_de_alerta: this.state.gestao_de_alerta
              }).then(r => {
                this.props.dialogClose()
                this.props.alert('Alterações salvas com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao salvar alterações'))
            }
          }}>Salvar</Button>
        </DialogActions>
      </Formsy>
    )
  }
}
export class Fact extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { searchField: '', dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  };

  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Facts').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<EditFact edit row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Edit />
            </IconButton>
          </TCell>
        }
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<ConfirmDialog
              title="Excluir Fato"
              msg="Tem certeza que deseja excluir o Fato:"
              reg={row.id + ' - ' + row.qualificator}
              onConfirm={() => {
                this.props.api.delete('/Fact/' + row.id).then(r => {
                  this.props.dialogClose()
                  this.updateData()
                  this.props.alert('Registro excluído com sucesso')
                }).catch(e => {
                  this.props.alert('Ocorreu um erro ao excluir o perfil')
                  this.props.dialogClose()
                  this.updateData()
                })
              }}
              onCancel={this.props.dialogClose} />)}>
              <Delete />
            </IconButton>
          </TCell>
        }
        <TCell component="th">{row.qualificator}</TCell>
        <TCell component="th">{row.tipo_de_dado}</TCell>
        <TCell component="th">{row.gerador_de_alerta ===true ? 'Sim' : 'Não'}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}>Nenhum registro encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e /><D size="2rem" />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.dialog(<EditFact
                  api={this.props.api}
                  alert={this.props.alert}
                  dialogClose={() => {
                    this.updateData()
                    this.props.dialogClose()
                  }} />)}><Add />Fato</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow sx={{ height: '3rem', background: '#ccc' }}>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell width="50%">Qualificador</TCell>
                  <TCell width="30%">tipo</TCell>
                  <TCell > Gerador de Alerta</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().replace(' ', '').includes(this.state.searchField.toLowerCase().replace(' ', ''))) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
