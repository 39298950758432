import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { Auth, ForgotPassword, ResetPassword, NotFound } from './Auth'
// import { PDFReader } from './views/PDFReader'
export class App extends React.Component {
  componentDidMount = () => {
    global.pdfjsLib = window['pdfjs-dist/build/pdf']
    global.pdfjsLib.GlobalWorkerOptions.workerSrc = './pdf.worker.js'
  }
  render() {
    return (
      <BrowserRouter>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<Auth />} />
            <Route exact path="/login" element={<Navigate replace to="/" />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password/:hash/:email" element={<ResetPassword />} />
            <Route exact path="/logout" element={<Auth logout />} />
            <Route exact path="/NotFound" element={<NotFound />} />
            <Route exact path="/:url" element={<Auth />} />
            {/* <Route exact path="/pdf/:id" element={<PDFReader />} /> */}
            <Route path="*" element={<NotFound />} />
            {/* <Route path="*" element={<Navigate replace to="/NotFound" />} /> */}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}