import React from 'react'
import Formsy from 'formsy-react'
import { Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { D, Loading, SearchField, TRow, TCell, TextFieldFormsy } from '../include'
class EditAttentionWord extends React.Component {
  constructor(props) {
    super(props)
    this.state = { nome: !!this.props.row ? this.props.row.texto : '' }
  }
  render() {
    return (
      <Formsy onValidSubmit={() => null} >
        {!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Adicionar Palavra</DialogTitle>}
        {!!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Editar Palavra</DialogTitle>}
        <DialogContent>
          <D h="1rem" />
          <TextFieldFormsy defaultValue={!!this.props.row ? this.props.row.texto : ''} value={this.state.nome} onChange={v => this.setState({ nome: v })} type="text" name="name" label="Nome" variant="outlined" required />
          <D h="2rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            if (!this.props.edit) {
              this.props.api.post('/AttWord/', { texto: this.state.nome }).then(r => {
                this.props.dialogClose()
                this.props.alert('Registro criado com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao criar o registro'))
            } else {
              this.props.api.put('/AttWord/' + this.props.row.id, { id: this.props.row.id, texto: this.state.nome }).then(r => {
                this.props.dialogClose()
                this.props.alert('Alterações salvas com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao salvar alterações'))
            }
          }}>Salvar</Button>
        </DialogActions>
      </Formsy >
    )
  }
}
class ConfirmAttentionWordDelete extends React.Component {
  render() {
    return (
      <>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir:</DialogContentText>
          <D h="1rem" />
          <DialogContentText align="center" variant="h6">{this.props.row.id + ' - ' + this.props.row.texto}</DialogContentText>
          <D h="1rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.delete('/AttWord/' + this.props.row.id).then(r => {
            this.props.dialogClose()
            this.props.alert('Registro excluído com sucesso')
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o registro'))}>Confirmar</Button>
        </DialogActions>
      </>
    )
  }
}
export class AttentionWord extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { searchField: '', dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/AttWords').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<EditAttentionWord edit row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Edit />
            </IconButton>
          </TCell>
        }
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<ConfirmAttentionWordDelete row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Delete />
            </IconButton>
          </TCell>
        }
        <TCell component="th">{row.texto}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={10}>Nenhuma palavra encontrada</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e /><D size="2rem" />
              <Button variant="contained" color="primary" onClick={() => this.props.dialog(<EditAttentionWord api={this.props.api} alert={this.props.alert} dialogClose={() => {
                this.updateData()
                this.props.dialogClose()
              }} />)}><Add />Palavra</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow sx={{ height: '3rem', background: '#ccc' }}>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell width="100%">Palavra</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().replace(' ', '').includes(this.state.searchField.toLowerCase().replace(' ', ''))) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
