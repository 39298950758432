import React from "react";
import { Autocomplete,TextField, Chip, Tooltip } from "@mui/material";
import { TimelineClass } from "./Timeline";
import Box from '@mui/material/Box';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {CheckBoxOutlineBlank, HelpOutline} from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

const oicon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export class Filter extends React.Component{
    constructor(props) {
        super(props)
        this.getFilterData = this.getFilterData.bind(this)
        this.state = {
            filterTimeline: {id: 21, name: 'Conclusão'},
            timelineFacts: [],
            timelineOptions: [ ],
            search: '',
            timelineFactsToModal: [],
            timelineFatosName: [],
            timelineOcorrencias: [],
            timelineFatosId: [],
            selectTimelineParameters: []
 		};
    }

getTimelineFacts = (value) => {  
        let idArray = [];
        this.state.filterTimeline.forEach(element => {
          if (value.find(obj => obj === element.name)) {
            idArray.push(element.id)
          }})
        this.setState({ timelineFacts: idArray, timelineFatosName: value });
    };

getTimelineFactsToOpenModal = (value) => {  
      let idArray = [];
      this.state.filterTimeline.forEach(element => {
        if (value.find(obj => obj === element.name)) {
          idArray.push(element.id)
        }})
      this.setState({ timelineFactsToModal: idArray });
  };

  getSelectTimelineParameters = async () => {
    const timelineParameters =  await this.props.api.get(`/users/selectParametersTimeline?user_id=${this.props.user.id}`)
      this.setState({
        timelineFacts: timelineParameters.selectFatosID,
        timelineFatosName: timelineParameters.selectFactName,
        selectTimelineParameters: timelineParameters.timeline
    });
  };

 componentDidMount = () => {
   this.getSelectTimelineParameters().then(() => {
     this.getFilterData()
   })
};

 getFilterData = () => {     
     this.props.api.get(`Facts`,
    ).then(r => {
        let factsArray = []
        r.forEach(d => {
            factsArray.push({
                id:d.id,
                name: d.qualificator,
          });
        })
        let options = []
        r.forEach(e=>{
            options.push(e.qualificator)
        })
        this.setState({timelineOptions: options})
        this.setState({filterTimeline: factsArray})
    })
            
 }
 QueryWithTooltip = () => (
	<Tooltip title="Fato1(ocorrências desejadas) + ... + FatoN(ocorrências desejadas)">
		<HelpOutline />
	</Tooltip>
 );
    render() {
        return(
            <>
            <Box sx={{ display: 'block', displayPrint: 'none',backgroundColor: 'white', padding:'1rem', borderRadius: '1rem' }} >
            <Stack >
                <Stack direction='row' spacing={1}>
            <Autocomplete
    	        value={this.state.timelineFatosName || []}
    	        multiple={true}
    	        sx={{width: '70%', paddingBottom:'1rem' }}
    	        getOptionLabel={(options) => options || null}
    	        renderOption={(props, option, { selected }) => (
    	            <li {...props }>
    	              <Checkbox
    	                icon={oicon}
    	                checkedIcon={checkedIcon}
    	                style={{ marginRight: 8 }}
    	                checked={selected}
    	              />
    	              {option}
    	            </li>
    	          )}
    	        onChange={(e,value)=>{ this.getTimelineFacts(value) } }
    	    	renderTags={(value, getTagProps) =>
    	        	value.map((option, index) => (
    	          		<Chip variant="outlined" label={option} onClick={() => this.getTimelineFactsToOpenModal([option])} {...getTagProps({ index })} />
    	        	))
    	      	}
    	        options={this.state.timelineOptions}
    	        isOptionEqualToValue={(option,value) => option === value }
    	        renderInput={(params) => <div>
    	        	<TextField {...params} 
						label={
							<div>
								Construção da Consulta {" "}
								< this.QueryWithTooltip />
							</div>
						}  
					/>
    	        	</div> 
	        	}
            >
			</Autocomplete> 
            <TextField
  	          sx={{marginLeft: '2px'}}
    	        type="text"
    	        variant="outlined"
    	        className="form-control"
    	        onChange={value => this.setState({search: value})}
    	        placeholder="Filtrar ..."
    	        ref={this.node}
    	        style={{ paddingBottom: '1rem', width: '50%' }} 
			/> 
            </Stack>
                <TimelineClass 
	                disabled
    	            factId={this.state.timelineFacts}
    	            api={this.props.api} dataInicio={this.props.dataInicio}
    	            dataFim={this.props.dataFim}
    	            search={this.state.search}
    	            factIdOpen={this.state.timelineFactsToModal}
    	            user={this.props.user}
    	            selectFactsName={this.state.timelineFatosName}
    	            // timelineFatosId={this.state.timelineFatosId}
    	            selectTimelineParameters={this.state.selectTimelineParameters}
					alert={this.props.alert}
                />
            </Stack>
            </Box>
            </>     
        )
    }
}
