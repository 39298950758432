import React from 'react'
import Formsy from 'formsy-react'
import { Button, Typography, Avatar, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { Upload, VpnKey } from '@mui/icons-material'
import { C, CenterContent, D, TextFieldFormsy } from '../include'
import Croppie from 'croppie'
import 'croppie/croppie.css'
export class CropPic extends React.Component {
  constructor(props) {
    super(props)
    this.cropButton = this.cropButton.bind(this)
    this.state = { croppieContent: null }
  }
  componentDidMount = () => {
    this.setState({
      croppieContent: new Croppie(document.getElementById('croppie'), {
        enableExif: false,
        viewport: {
          width: '320px',
          height: '320px',
          type: 'circle',
        },
        boundary: {
          width: '340px',
          height: '340px',
        },
        url: URL.createObjectURL(document.getElementById(this.props.input).files[0]),
      }),
    })
  }
  cropButton = () => {
    this.state.croppieContent
      .result({
        size: 'viewport',
        format: 'png',
        type: 'blob',
      })
      .then((r) => {
        const croppedPic = new FormData()
        croppedPic.append('file', r, 'croppie.png')
        this.props.api.post('profile', croppedPic).then(r => {
          if (r.status === 'upload_succed') {
            this.props.updateAppData()
          }
          this.props.dialogClose()
        })
      })
  }
  render() {
    return (
      <D style={{ padding: '3rem' }}>
        <D id="croppie" />
        <D h="30px" />
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.cropButton()}><Upload />Enviar</Button>
        </DialogActions>
      </D>
    )
  }
}
class ChangePwd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayName: !!this.props.user ? this.props.user.name : '',
      email: !!this.props.user ? this.props.user.email : '',
      password: ''
    }
  }
  render() {
    return (
      <Formsy onValidSubmit={() => null}>
        <DialogTitle style={{ minWidth: '400px' }}>Trocar senha</DialogTitle>
        <DialogContent>
          <D h="0.5rem" />
          <TextFieldFormsy required type="password"  defaultValue={this.state.password} value={this.state.password} onChange={v => this.setState({ password: v })}
            name="password" label="Nova senha" validations={{ minLength: 4 }} validationErrors={{ minLength: 'Muito curto' }} />
          <D size="1rem" />
          <TextFieldFormsy required type="password"  defaultValue={this.state.passwordConfirm} value={this.state.passwordConfirm} onChange={v => this.setState({ passwordConfirm: v })}
            name="passwordConfirm" label="Confirmar senha" validations="equalsField:password" validationErrors={{ equalsField: 'Senhas diferentes' }} />
          <D h="0.5rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            const dt = {
              displayName: this.state.displayName,
              email: this.state.email,
              password: this.state.password
            }
            this.props.api.put('/user_data/', dt).then(r => {
              this.props.dialogClose()
              this.props.alert('Senha alterada com sucesso')
            }).catch(e => this.props.alert('Ocorreu um erro ao alterar a senha'))
          }}>Salvar</Button>
        </DialogActions>
      </Formsy>
    )
  }
}
export class MyProfile extends React.Component {
  render() {
    return (
      <CenterContent className="r2c e aF scB" style={{ padding: '2rem 3rem' }} p="11">
        <D className="col aC">
          {!!this.props.user.photo ? <img alt="" src={C.baseURL + 'pic/' + this.props.user.photo} style={{ width: '200px', height: '200px' }} /> : <Avatar sx={{ width: 200, height: 200 }} />}
          <input
            name="file"
            className="hide"
            type="file"
            id="avatarPic"
            accept="image/*;capture=camera"
            onChange={() => this.props.dialog(<CropPic
              api={this.props.api}
              updateAppData={() => this.props.updateAppData()}
              input={'avatarPic'}
              dialogClose={this.props.dialogClose} />
            )} />
          <D h="2rem" />
          <D><Typography variant="h4" color="inherit">{this.props.user.name}</Typography></D>
          <D h="2rem" />
          <Button variant="outlined" color="primary" onClick={() => document.getElementById('avatarPic').click()} ><Upload />Trocar foto</Button>
          <D e />
        </D>
        <D size="3rem" h="3rem" />
        <D className="col e aF">
          <Formsy>
            <TextFieldFormsy type="text" name="name" label="Nome" defaultValue={this.props.user.name}  disabled={!this.props.edit} />
            <TextFieldFormsy type="text" name="email" label="Email" defaultValue={this.props.user.email}  disabled={!this.props.edit} />
            <TextFieldFormsy type="text" name="perfil" label="Perfil de Acesso" defaultValue={this.props.user.role}  disabled={!this.props.edit} />
            <TextFieldFormsy type="text" name="court" label={C.businessLabel} defaultValue={this.props.user.court}  disabled={!this.props.edit} />
            <TextFieldFormsy type="text" name="section" label={C.divisionLabel} defaultValue={this.props.user.section}  disabled={!this.props.edit} />
            <D h="2rem" />
            <D className="row w aC">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.props.dialog(<ChangePwd
                  api={this.props.api}
                  user={this.props.user}
                  alert={this.props.alert}
                  dialogClose={this.props.dialogClose} />)}><VpnKey />Trocar senha</Button>
            </D>
            <D h="2rem" />
          </Formsy>
        </D>
      </CenterContent>
    )
  }
}
