import React from 'react'
import { Button, Typography, IconButton, Table, TableContainer, TableHead, TableBody, Paper } from '@mui/material'
import { RemoveRedEye } from '@mui/icons-material'
import { D, Loading, TRow, TCell } from '../include'
import Moment from 'react-moment'
import 'moment/locale/pt-br'
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
ChartJS.register(ArcElement)
export class Equipament extends React.Component {
  constructor(props) {
    super(props)
    this.update = this.update.bind(this)
    this.state = { data: null }
  }
  componentDidMount = () => {
    this.interval = setInterval(() => this.update(), 2000)
    this.update()
  }
  componentWillUnmount = () => clearInterval(this.interval)
  update = () => {
    this.props.api.get('/server').then(r => this.setState({ data: r }))
  }
  render() {
    return (
      <>
        {!!this.state.data ?
          <D className="col e aF scD" style={{ padding: '2rem 4rem' }}>
            <D className="row w aC">
              <Typography variant="h6">{'Nome do host: ' + this.state.data.hostname}</Typography>
            </D>
            <D h="2rem" />
            <D className="row w aC">{this.state.data.so + ' ' + this.state.data.kernel_version + ' -'}&nbsp;<Moment locale="en" parse="dddd D, MMMM YYYY" date={this.state.data.running_since} toNow ago />&nbsp;{'online - ' + (this.state.data.num_cpu_cores + 1).toString() + ' cores / ' + this.state.data.num_processes + ' procs'}</D>
            <D className="row w aC"></D>
            <D h="2rem" />
            <D className="row w aC" style={{ padding: '1rem' }}>
              <D className="col e aC" style={{ width: '80px' }}>
                <Typography variant="subtitle1" color="textSecondary">Processador</Typography>
                <Doughnut data={{ datasets: [{ data: [this.state.data.cpu_usage, 100 - this.state.data.cpu_usage], backgroundColor: ['#ffb300', '#eee'] }] }} />
                <Typography variant="subtitle1" color="textSecondary">{this.state.data.cpu_usage.toFixed(0) + '%'}</Typography>
              </D>
              <D size="2rem" />
              <D className="col e aC" style={{ width: '80px' }}>
                <Typography variant="subtitle1" color="textSecondary">RAM</Typography>
                <Doughnut data={{ datasets: [{ data: [this.state.data.memo_ram_usage, this.state.data.memo_ram - this.state.data.memo_ram_usage], backgroundColor: ['#ffb300', '#eee'] }] }} />
                <Typography variant="subtitle1" color="textSecondary">{this.state.data.memo_ram_usage.toFixed(1) + '/' + this.state.data.memo_ram.toFixed(0)}</Typography>
              </D>
              <D size="2rem" />
              <D className="col e aC" style={{ width: '80px' }}>
                <Typography variant="subtitle1" color="textSecondary">Swap</Typography>
                <Doughnut data={{ datasets: [{ data: [this.state.data.memo_swap, this.state.data.memo_swap_usage - this.state.data.memo_swap], backgroundColor: ['#ffb300', '#eee'] }] }} />
                <Typography variant="subtitle1" color="textSecondary">{this.state.data.memo_swap.toFixed(1) + '/' + this.state.data.memo_swap_usage.toFixed(0)}</Typography>
              </D>
              <D size="2rem" />
              <D className="col e aC" style={{ width: '80px' }}>
                <Typography variant="subtitle1" color="textSecondary">Disco</Typography>
                <Doughnut data={{ datasets: [{ data: [this.state.data.disk_size_usage, this.state.data.disk_size - this.state.data.disk_size_usage], backgroundColor: ['#ffb300', '#eee'] }] }} />
                <Typography variant="subtitle1" color="textSecondary">{this.state.data.disk_size_usage.toFixed(1) + '/' + this.state.data.disk_size.toFixed(0)}</Typography>
              </D>
            </D>
            <D h="2rem" />
            <D className="row w aC">
              <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Fechar</Button>
            </D>
            {/* <Widget data={this.state.data.cpu_temperature} /> */}
            {/* <Widget data={this.state.data.frequencies} /> */}
          </D> : <D className="row e aC" style={{ minWidth: '200px', minHeight: '200px' }}><Loading /></D>
        }
      </>
    )
  }
}
export class Servers extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Equipments').then(r => this.updateTable(r.computers))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TCell align="center" component="th">
          <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<Equipament api={this.props.api} row={row} dialogClose={() => this.props.dialogClose()} />)}>
            <RemoveRedEye />
          </IconButton>
        </TCell>
        {row.status === false ?
          <TCell component="th" align="center" style={{ color: 'red' }} sx={{ fontWeight: 'bold' }}>Inativo</TCell> :
          <TCell component="th" align="center" style={{ color: 'green' }} sx={{ fontWeight: 'bold' }}>Ativo</TCell>}
        <TCell component="th">{row.id}</TCell>
        <TCell component="th">{row.type}</TCell>
        <TCell component="th">{row.ip}</TCell>
        <TCell component="th">{row.alias}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}>Nenhum servidor encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow>
                  <TCell align="center">Contole</TCell>
                  <TCell>Status</TCell>
                  <TCell>ID</TCell>
                  <TCell>Tipo</TCell>
                  <TCell>IP</TCell>
                  <TCell width="100%">Nome</TCell>
                </TRow>
              </TableHead>
              <TableBody>{this.state.rows}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
