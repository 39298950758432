import React from 'react'
import Formsy from 'formsy-react'
import { Typography, Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, FormControlLabel } from '@mui/material'
import { Add, Edit, Delete } from '@mui/icons-material'
import { D, Loading, SearchField, TRow, TCell, TextFieldFormsy } from '../include'
import _ from 'underscore'
class AccessProfileForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      permissionsData: this.props.permissionsData,
      id: !!this.props.row ? this.props.row.id : null,
      name: !!this.props.row ? this.props.row.name : '',
      ids: !!this.props.row ? this.props.row.permission_id : []
    }
  }
  render() {
    return (
      <Formsy onValidSubmit={() => null}>
        {!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Novo perfil de acesso</DialogTitle>}
        {!!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Editar perfil de acesso</DialogTitle>}
        <D style={{ paddingLeft: '24px', paddingRight: '24px' }}>
          <TextFieldFormsy defaultValue={this.state.name} value={this.state.name} onChange={v => this.setState({ name: v })} type="text" name="name" label="Nome do perfil de acesso"  />
          <D h="1rem" />
          <Typography variant="h6" component="div">Tipos de acessos do perfil</Typography>
        </D>
        <DialogContent className='scD' style={{ maxHeight: '40vh' }}>
          {!!this.state.permissionsData &&
            <D className='scB' style={{ background: '#efefef', borderRadius: '5px' }}>
              {Object.keys(this.state.permissionsData).map(p => {
                const perm_read = _.findWhere(this.state.permissionsData[p], { name: "Ler" })
                const perm_write = _.findWhere(this.state.permissionsData[p], { name: "Escrever" })
                return (
                  <D key={p} className="row aF" style={{ padding: '0.5rem' }}>
                    <D e>{p}</D>
                    <FormControlLabel
                      value="Ler"
                      label="Ler"
                      labelPlacement="end"
                      control={<Checkbox
                        checked={this.state.ids.indexOf(!!perm_read && perm_read.id) !== -1}
                        onChange={() => this.setState({ ids: this.state.ids.indexOf(perm_read.id) > -1 ? _.without(this.state.ids, perm_read.id) : _.union(this.state.ids, [perm_read.id]) })} />}
                      disabled={!perm_read} />
                    <FormControlLabel
                      value="Escrever"
                      label="Escrever"
                      labelPlacement="end"
                      control={<Checkbox
                        checked={this.state.ids.indexOf(!!perm_write && perm_write.id) !== -1}
                        onChange={() => this.setState({ ids: this.state.ids.indexOf(perm_write.id) > -1 ? _.without(this.state.ids, perm_write.id) : _.union(this.state.ids, [perm_write.id]) })} />}
                      disabled={!perm_write} />
                  </D>
                )
              })}
            </D>
          }
        </DialogContent>
        <D h="1rem" />
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            const dt = {
              name: this.state.name,
              ids: this.state.ids
            }
            if (!this.props.edit) {
              this.props.api.post('/UserProfiles', dt).then(r => {
                this.props.dialogClose()
                this.props.alert(r.message)
              }).catch(e => this.props.alert('Ocorreu um erro ao excluir o perfil'))
            } else {
              this.props.api.put('/UserProfile/' + this.state.id, dt).then(r => {
                this.props.dialogClose()
                this.props.alert('Alterações salvas com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao excluir o perfil'))
            }
          }}>Salvar</Button>
        </DialogActions>
      </Formsy >
    )
  }
}
class ConfirmProfileDelete extends React.Component {
  render() {
    return (
      <>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir o perfil de acesso:</DialogContentText>
          <D h="1rem" />
          <DialogContentText align="center" variant="h6">{this.props.row.id + ' - ' + this.props.row.name}</DialogContentText>
          <D h="1rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.delete('/UserProfile/' + this.props.row.id).then(r => {
            this.props.dialogClose()
            this.props.alert(r.message)
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o perfil'))}>Confirmar</Button>
        </DialogActions>
      </>
    )
  }
}
export class AccessProfile extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = {
      searchField: '',
      dataTable: [],
      permissionsData: [],
      rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>]
    }
  }
  componentDidMount = () => this.updateData()
  updateData = () => {
    this.props.api.get('/UserProfiles').then(r => this.updateTable(r))
    this.props.api.get('/Permissions').then(r => this.setState({ permissionsData: _.groupBy(r, 'category') }))
  }
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(
        <TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {this.props.permission === 'w' && <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(
              <AccessProfileForm edit
                row={row}
                permissionsData={this.state.permissionsData}
                api={this.props.api}
                alert={this.props.alert}
                dialogClose={() => {
                  this.updateData()
                  this.props.dialogClose()
                }} />)}>
              <Edit />
            </IconButton>
          </TCell>}
          {this.props.permission === 'w' && <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(
              <ConfirmProfileDelete
                row={_.findWhere(this.state.dataTable, { id: row.id })}
                api={this.props.api}
                alert={this.props.alert}
                dialogClose={() => {
                  this.updateData()
                  this.props.dialogClose()
                }} />)}>
              <Delete />
            </IconButton>
          </TCell>}
          <TCell component="th">{row.id}</TCell>
          <TCell component="th">{row.name}</TCell>
        </TRow>
      )
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}>Nenhum perfil encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e /><D size="2rem" />
              <Button variant="contained" color="primary"
                onClick={() => this.props.dialog(
                  <AccessProfileForm
                    permissionsData={this.state.permissionsData}
                    api={this.props.api}
                    alert={this.props.alert}
                    dialogClose={() => {
                      this.updateData()
                      this.props.dialogClose()
                    }} />
                )}><Add />Perfil</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell>ID</TCell>
                  <TCell width="100%">Perfil de acesso</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
