import React from 'react'
import Formsy from 'formsy-react'
import { Autocomplete, TextField, Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { C, D, Loading, SearchField, TRow, TCell, TextFieldFormsy, CustomSelect } from '../include'
import _ from 'underscore'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const oicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayName: !!this.props.row ? this.props.row.name : '',
      email: !!this.props.row ? this.props.row.email : '',
      accessProfile: !!this.props.row ? this.props.row.role : '',
      court: !!this.props.row ? this.props.row.court : '',
      section: !!this.props.row ? this.props.row.section : '',
      password: '',
      passwordConfirm: '',
      accessProfileSelector: [],
      courtSelector: [],
      sectionSelector: [],
      gruposName: [],
      selectGrupos: [],
      funcao: !!this.props.row ? this.props.row.funcao : '',
      registraleitura: !!this.props.row ? this.props.row.registraleitura : true,
      grupos: [],
      grupoId: []
    }
  }
  getGrupoId = (value) => {
    let idArray = [];
    this.state.grupos.forEach(element => {
      if (value.find(obj => obj === element.nome)) {
        idArray.push(element.id)
      }
    })
    this.setState({ grupoId: idArray });
  };

  async getSelectgrupos  () {
    const data =  await  this.props.api.get(`/users/grupos?selectUser=${JSON.stringify([this.props.row.id])}`);
    let gruposName = [];
    let gruposIds = [];
    data.map(value => gruposName.push(value.nome) 
     && gruposIds.push(value.grupo_id)
     )
      this.setState({grupoId: gruposIds})
    return gruposName
  };

  componentDidMount = () => {
    this.props.api.get('/UserProfiles').then(r => this.setState({
      profilesData: r,
      accessProfileSelector: r.map(z => z.name)
    }));

    this.props.api.get('/Courts').then(r => this.setState({
      courtData: r,
      // courtSelector: r.map(z => z.nome)
    }));

    this.props.api.get('/Sections').then(r => this.setState({
      sectionData: r,
      courtSelector: Object.keys(_.groupBy(r, 'court')),
      sectionSelector: r.map(z => z.name)
    }));

    this.props.api.get('/Grupos').then(response => {
      let grupoArray = [];
      response.forEach(element => {
        grupoArray.push(element.nome)
      })
      this.setState({ gruposName: grupoArray, grupos: response })
    });
    if (this.props.edit) {
      this.getSelectgrupos().then((values) => this.setState({selectGrupos: values}))
    };
  }
  
  render() {
    return (
      <Formsy onValidSubmit={() => null}>
        {!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Novo usuário</DialogTitle>}
        {!!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Editar usuário</DialogTitle>}
        <DialogContent>
          <D h="0.5rem" />
          <TextFieldFormsy required type="text" defaultValue={this.state.displayName} value={this.state.displayName} onChange={v => this.setState({ displayName: v })}
            name="displayName" label="Nome" validations={{ minLength: 4 }} validationErrors={{ minLength: 'Muito curto' }} />
          <TextFieldFormsy required type="text" defaultValue={this.state.email} value={this.state.email} onChange={v => this.setState({ email: v })}
            name="email" label="E-mail" validations="isEmail" validationErrors={{ isEmail: 'Email inválido' }} disabled={!this.props.edit ? false : true} />
          <D className="row aC">
            <TextFieldFormsy required type="password" defaultValue={this.state.password} value={this.state.password} onChange={v => this.setState({ password: v })}
              name="password" label="Nova senha" validations={{ minLength: 4 }} validationErrors={{ minLength: 'Muito curto' }} />
            <D size="1rem" />
            <TextFieldFormsy required type="password" defaultValue={this.state.passwordConfirm} value={this.state.passwordConfirm} onChange={v => this.setState({ passwordConfirm: v })}
              name="passwordConfirm" label="Confirmar senha" validations="equalsField:password" validationErrors={{ equalsField: 'Senhas diferentes' }} />
          </D>
          <CustomSelect label="Perfil de acesso" options={this.state.accessProfileSelector} value={this.state.accessProfile} onChange={v => this.setState({ accessProfile: v })} />
          <CustomSelect label={C.businessLabel} options={this.state.courtSelector} value={this.state.court} onChange={v => {
            this.setState({ court: v, section: '', sectionSelector: _.groupBy(this.state.sectionData, 'court')[v].map(z => z.name) })
          }} />
          <CustomSelect label={C.divisionLabel} options={this.state.sectionSelector} value={this.state.section} onChange={v => this.setState({ section: v })} disabled={!this.state.court} />



<FormGroup>
  <FormControlLabel control={<Checkbox checked={this.state.registraleitura} value={this.state.registraleitura} onChange={e =>{this.setState({registraleitura: e.target.checked})}}  />} label="Registra leituras de documentos" />
</FormGroup>

          <TextFieldFormsy type="text" defaultValue={this.state.funcao} value={this.state.funcao} onChange={v => this.setState({ funcao: v })}
            name="funcao" label="Função"/>
          <D h="1rem" />
          <Autocomplete
            multiple={true}
            id="checkboxes-tags-demo"
            options={this.state.gruposName}
            value={this.state.selectGrupos}
            getOptionLabel={(options) => options || ''}
            isOptionEqualToValue={(option, value) =>
              option === value
            }
            renderOption={(props, option, { selected }) => (
              <li {...props }>
                <Checkbox
                  icon={oicon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
 
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Grupos" placeholder="" />
            )}
            onChange={(e, value) => {
              this.setState({selectGrupos: value})
              this.getGrupoId(value)
            }} />

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            if(!this.state.accessProfile){
              this.props.alert('Informe o perfil de acesso')
              return
            }
            if(!this.state.court){
              this.props.alert('Informe ' + C.businessLabel)
              return
            }
            if(!this.state.section){
              this.props.alert('Informe ' + C.divisionLabel)
              return
            }
            const dt = {
              displayName: this.state.displayName,
              email: this.state.email,
              password: this.state.password,
              'password-confirm': this.state.passwordConfirm,
              grupo_id: JSON.stringify(this.state.grupoId),
              funcao: this.state.funcao,
              registraleitura: this.state.registraleitura,
              accessProfile: {
                label: this.state.accessProfile,
                value: _.where(this.state.profilesData, { name: this.state.accessProfile })[0].id
              },
              section: {
                label: this.state.section,
                value: _.where(this.state.sectionData, { name: this.state.section })[0].id
              }
            }
            if (!this.props.edit) {
              this.props.api.post('/user', dt).then(r => {
                if(r.user){
                  this.props.dialogClose()
                  this.props.alert('Usuário criado com sucesso')
                }
                else
                  this.props.alert('Preencha os campos obrigatórios')
              }).catch(e => this.props.alert('Ocorreu um erro ao excluir o usuário'))
            } else {
              this.props.api.put('/user', dt).then(r => {
                this.props.dialogClose()
                this.props.alert('Alterações salvas com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao excluir o usuário'))
            }
          }}>Salvar</Button>
        </DialogActions>
      </Formsy>
    )
  }
}
class ConfirmUserDelete extends React.Component {
  render() {
    return (
      <>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText className="row w aC">Tem certeza que deseja excluir o usuário?</DialogContentText>
          <DialogContentText className="row w aC">Esta ação não poderá ser desfeita!</DialogContentText>
          <D h="1rem" />
          <DialogContentText align="center" variant="h6">{this.props.row.id + ' - ' + this.props.row.name}</DialogContentText>
          <D h="1rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.delete('/User/' + this.props.row.id).then(r => {
            this.props.dialogClose()
            if (r.status === 'ok') {
              this.props.alert('Registro excluído com sucesso')
            } else if (r.status === 'not_authorized') {
              this.props.alert('Você não tem autorização para excluir este registro')
            } else {
              this.props.alert('Ocorreu um erro ao excluir o registro')
            }
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o registro'))}>Confirmar</Button>
        </DialogActions>
      </>
    )
  }
}
export class Users extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { searchField: '', dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={8}><Loading /></TCell></TRow>] }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Users').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<UserForm edit row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Edit />
            </IconButton>
          </TCell>
        }
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<ConfirmUserDelete row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Delete />
            </IconButton>
          </TCell>
        }
        <TCell component="th">{row.id}</TCell>
        <TCell component="th" style={{ minWidth: '15rem' }}>{row.name}</TCell>
        <TCell component="th" style={{ minWidth: '15rem' }}>{row.email}</TCell>
        <TCell component="th">{row.role}</TCell>
        <TCell component="th" style={{ minWidth: '15rem' }}>{row.court}</TCell>
        <TCell component="th" style={{ minWidth: '15rem' }}>{row.section}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={8}>Nenhum usuário encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e /><D size="2rem" />
              <Button variant="contained" color="primary" onClick={() => this.props.dialog(<UserForm api={this.props.api} alert={this.props.alert} dialogClose={() => {
                this.updateData()
                this.props.dialogClose()
              }} />)}><Add />Usuário</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell>ID</TCell>
                  <TCell>Nome</TCell>
                  <TCell width="100%">Email</TCell>
                  <TCell>Perfil de acesso</TCell>
                  <TCell>{C.businessLabel}</TCell>
                  <TCell>{C.divisionLabel}</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().replace(' ', '').includes(this.state.searchField.toLowerCase().replace(' ', ''))) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
