import React from 'react'
import { IconButton, Table, TableContainer, TableHead, TableBody, Paper } from '@mui/material'
import { StopCircle, PlayCircle } from '@mui/icons-material'
import { D, Loading, TRow, TCell } from '../include'
class DaemonRow extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.state = { status: null }
  }
  componentDidMount = () => this.updateData()
  updateData = () => {
    this.props.api.post('Daemon/status', { name: this.props.row.alias }).then(r => this.setState({ status: r.status }))
  }
  render() {
    return (
      <TRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TCell component="th" align="center">
          <IconButton style={{ color: '#aaa' }} onClick={() => this.props.api.post('/Daemon/' + (this.state.status === 'off' ? 'start' : 'stop'), { name: this.props.row.alias }).then(() => this.updateData())}>
            {this.state.status === 'off' ? <PlayCircle /> : <StopCircle />}
          </IconButton>
        </TCell>
        {!this.state.status && <TCell component="th" align="center"><Loading /></TCell>}
        {!!this.state.status && <>
          {this.state.status === 'off' ?
            <TCell component="th" align="center" style={{ color: 'red' }} sx={{ fontWeight: 'bold' }}>Inativo</TCell> :
            <TCell component="th" align="center" style={{ color: 'green' }} sx={{ fontWeight: 'bold' }}>Ativo</TCell>}
        </>}
        <TCell component="th">{this.props.row.ip}</TCell>
        <TCell component="th">{this.props.row.alias}</TCell>
        <TCell component="th">{this.props.row.name}</TCell>
      </TRow>
    )
  }
}
export class Daemons extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = {
      dataTable: [],
      rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={5}><Loading /></TCell></TRow>]
    }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Daemons').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<DaemonRow key={Math.random().toString()} row={row} api={this.props.api} />)
    }) : (res = <TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={5}>Nenhum daemon encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow>
                  <TCell align="center">Controle</TCell>
                  <TCell align="center">Status</TCell>
                  <TCell>IP</TCell>
                  <TCell>Alias</TCell>
                  <TCell width="100%">Nome</TCell>
                </TRow>
              </TableHead>
              <TableBody>{this.state.rows}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
