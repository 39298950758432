import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#0070c4',
      },
      secondary: {
        main: '#ffb300',
      },
      error: {
        main: '#ff1744',
      },
      info: {
        main: '#3e3e3e',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    }
  })
  