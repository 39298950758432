import React from "react";
import { Tooltip } from "@mui/material";
import { Visibility} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { D, C } from "../include";

const style = {
  overflow: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', 
  maxWidth: '90vw',
  minWidth: '70vw',
  minHeight: '60vh',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  position: "absolute"
};

export class TimelineCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardBody: [],
      cardImg: '',
      cardState: false,
      open: false
    }
  };
  handleOpen = () => { this.setState({ open: true }) };
  handleClose = () => { this.setState({ close: false }) };

  onCloseCard = () => {
    this.setState({ cardState: true })
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.doc_id !== prevProps.doc_id) {
  //     this.props.api.get(`Timeline/documentos/${this.props.doc_id}`).then(r => {
  //       this.setState({ cardBody: r, cardImg:r[0].thumb_png })
  //     })
  //   }
  // };

  componentDidMount = () => {
    this.props.api.get(`Timeline/documentos/${this.props.doc_id}`).then(r => {
      this.setState({ cardBody: r, cardImg:r[0].thumb_png })
    })
  }
  render() {
    return (
      <>
        <Modal
          open={this.props.stateCard}
          onClose={this.props.onCloseCard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <header style={{ paddingBottom: "6px", borderBottom: "1px solid gray",flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
              <div>
              <h2 style={{ padding: 0, margin: 0, color: "#3f3f3f" }}>Detalhes</h2>
              </div>
           <div style={{textAlign:'end',padding:0,margin:0}}>
            <CloseIcon onClick={this.props.onCloseCard} sx={{":hover":{
              color:'grey',backgrounColor:'white'
            }}}/>
           </div>
            
            </header>
            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', overflowY: 'scroll', height: 'calc(100% - 72px)' }}>
              <div style={{ width: "50%" }}>
                {this.state.cardImg &&
                <img style={{ width: "100%", boxSizing: "border-box" }} src={C.baseURL + 'pic/' +  this.state.cardImg} alt={this.state.cardImg}/>
                }
                {/* <D e side img={C.baseURL + 'pic/' + this.state.cardImg} /> */}
              </div>

              <D className="col e aF" style={{ display: 'flex', width: '50%' }}>
                {this.state.cardBody.map(e =>
                  <D key={e.qualificador_id} col style={{ padding: '7px', overflow: '' }}>
                    <span sx={{ fontWeight: 'bold', color: '#7a7a7a' }}>{e.qualificador_nome}:</span>
                    <span sx={{ fontWeight: '100' }}> { e.quantificador_homem === null ? e.quantificador_maquina :e.quantificador_homem }</span>
                  </D>
                )}
              </D>
            </div>
            <footer style={{ height: "50px", paddingTop: "6px", borderTop: "1px solid gray", bottom: 0, display: "flex", flexDirection: "row-reverse", paddingRight: "1rem", alignItems: "center", gap: "10px" }}>
              <Stack spacing={1} direction='row'>
                <Tooltip title="Abrir painel de leitura" arrow>
                  <Visibility height="48px" className="hv" color="action" onClick={() => window.open('/viewer.html?p=' + this.props.doc_id, '_blank')} />
                </Tooltip>
               
              </Stack></footer>

          </Box>
        </Modal>
      </>

    )
  }
}