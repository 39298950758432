import { C, D } from './include'
import { Layers, ViewList, LibraryBooks, Equalizer, NetworkCheck, Computer, ThreeSixty, Build, Person, Lock, SupervisedUserCircle } from '@mui/icons-material'
import { Mediateca } from './views/Mediateca'
import { Dashboard } from './views/Dashboard'
import { Servers } from './views/Servers'
import { Daemons } from './views/Daemons'
import { SysConfig } from './views/SysConfig'
import { Users } from './views/Users'
import { Permissions } from './views/Permissions'
import { AccessProfile } from './views/AccessProfile'
import { MyProfile } from './views/MyProfile'
import { Business } from './views/Business'
import { Sections } from './views/Sections'
import { AttentionWord } from './views/AttentionWord'
import { Fact } from './views/Fact'
import { Logs } from './views/Logs'
import {Grupo} from './views/Group'
export const navigation = [
  {
    id: 'mediateca',
    title: 'Mediateca',
    type: 'item',
    icon: <LibraryBooks sx={{ color: '#fff' }} />,
    component: <Mediateca />,
    url: '/mediateca',
    permission: 'Mediateca-Ler',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Equalizer sx={{ color: '#fff' }} />,
    component: <Dashboard />,
    url: '/dashboard',
    permission: 'Dashboard-Ler',
  },
  {
    id: 'register',
    title: 'Cadastros',
    type: 'collapse',
    icon: <ViewList sx={{ color: '#fff' }} />,
    permission: 'Cadastro-Ler',
    children: [
      {
        id: 'court',
        title: C.businessLabel,
        type: 'item',
        icon: <ViewList sx={{ color: '#fff' }} />,
        component: <Business />,
        url: '/court',
        permission: 'Tribunal-Ler',
      },
      {
        id: 'organization',
        title: C.divisionLabel,
        type: 'item',
        icon: <ViewList sx={{ color: '#fff' }} />,
        component: <Sections />,
        url: '/section',
        permission: 'Vara-Ler',
      },
      {
        id: 'attentionword',
        title: 'Palavra de atenção',
        type: 'item',
        icon: <ViewList sx={{ color: '#fff' }} />,
        component: <AttentionWord />,
        url: '/attention',
        permission: 'Mediateca-Ler',
      },
      {
        id: 'fact',
        title: 'Fato',
        type: 'item',
        icon: <ViewList sx={{ color: '#fff' }} />,
        component: <Fact />,
        url: '/fact',
        permission: 'Fato-Ler',
      },
      {
        id: 'grupo',
        title: C.groupLabel,
        type: 'item',
        icon: <ViewList sx={{ color: '#fff' }} />,
        component: <Grupo/>,
        url: '/grupo',
        permission: 'Usuário-Ler',
      },
    ]
  },
  {
    id: 'user',
    title: 'Usuários',
    type: 'item',
    icon: <Person sx={{ color: '#fff' }} />,
    component: <Users />,
    url: '/user',
    permission: 'Usuário-Ler',
  },
  {
    id: 'permission',
    title: 'Permissões',
    type: 'item',
    icon: <Lock sx={{ color: '#fff' }} />,
    component: <Permissions />,
    url: '/permission',
    permission: 'Permissão-Ler',

  },
  {
    id: 'access-profile',
    title: 'Perfis de Acesso',
    type: 'item',
    icon: <SupervisedUserCircle sx={{ color: '#fff' }} />,
    component: <AccessProfile />,
    url: '/access-profile',
    permission: 'Perfil de Acesso-Ler',
  },
  {
    id: 'example-component',
    title: 'Painel de Controle',
    type: 'collapse',
    icon: <NetworkCheck sx={{ color: '#fff' }} />,
    permission: 'Painel de Controle-Ler',
    children: [
      {
        id: 'equipment',
        title: 'Equipamentos',
        type: 'item',
        icon: <Computer sx={{ color: '#fff' }} />,
        component: <Servers />,
        url: '/server',
        permission: 'Equipamento-Ler',
      },
      {
        id: 'daemons',
        title: 'Daemons',
        type: 'item',
        icon: <ThreeSixty sx={{ color: '#fff' }} />,
        component: <Daemons />,
        url: '/daemons',
        permission: 'Daemon-Ler',
      },
      {
        id: 'sysconfig',
        title: 'Configurações do Sistema',
        type: 'item',
        icon: <Build sx={{ color: '#fff' }} />,
        component: <SysConfig />,
        url: '/sysconfig',
        permission: 'Config. Sistema-Ler',
      },
      {
        id: 'logs',
        title: 'Logs de acesso',
        type: 'item',
        icon: <Layers sx={{ color: '#fff' }} />,
        component: <Logs />,
        url: '/logs',
        permission: 'Equipamento-Ler',
      }
    ]
  },
  {
    id: 'myprofile',
    title: 'Meu Perfil',
    type: 'route',
    icon: null,
    component: <MyProfile />,
    url: '/profile',
    permission: 'Usuário-Ler'
  },
  {
    id: 'logout',
    title: 'Sair',
    type: 'route',
    icon: null,
    component: <D />,
    url: '/logout',
    permission: ''
  }
]
