import React from 'react'
import { useLocation } from "react-router-dom"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Tooltip, Box, Divider, IconButton, Card, CardContent, CardHeader, Typography, Table, TextField, DialogActions } from '@mui/material'
import { TableContainer, TableHead, TableBody, Paper, Accordion, AccordionSummary, Menu, Button } from '@mui/material'
import { AccordionDetails, CircularProgress, Skeleton, Checkbox, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import { FileDownload, Delete, ViewList, GridView, ExpandLess, Pending, CheckCircle, ErrorOutline, DateRangeOutlined } from '@mui/icons-material'
import { ExpandMore, CheckCircleOutline, CloudCircle, Description, ModelTraining, CloudUpload } from '@mui/icons-material'
import { DangerousOutlined, Close, InfoOutlined, Visibility, SyncDisabledOutlined, Sync, Edit, SensorOccupied, Person, Build } from '@mui/icons-material'
import { C, D, SearchFieldMediateca, TRow, TCell, TSortLabel, ConfirmDialog, CustomSelect, Input, checker, MessageDialog } from '../include'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import Pagination from '@mui/material/Pagination';
import { saveAs } from 'file-saver'
import { visuallyHidden } from '@mui/utils'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import _ from 'underscore'
import moment from 'moment'
moment.locale('pt_br')
class UploadListCard extends React.Component {
  render() {
    return (
      <D col>
        <D h="0.5rem" />
        <D className="row aC">
          <Description sx={{ color: '#ccc', paddingRight: '5px' }} />
          <D className="th">{this.props.title}</D>
          <D e />
          {this.props.status === 'Pending' && <Pending sx={{ color: '#ccc' }} />}
          {this.props.status === 'Uploading' && <CloudCircle sx={{ color: '#ffb300' }} />}
          {this.props.status === 'Done' && <CheckCircle sx={{ color: 'green' }} />}
          {this.props.status === 'Error' && <DangerousOutlined sx={{ color: 'red' }} />}
        </D>
        <D h="0.5rem" />
        <Divider />
      </D>
    )
  }
}
class MediatecaQualificators extends React.Component {
  render() {
    return (
      <D className="col e aF">
        {!!this.props.ocs && this.props.ocs.length > 0 && this.props.ocs.map(o =>
          <D col key={o.qualificador_nome} style={{ padding: '7px' }}>
            <Typography variant="caption" component="div" color="text.secondary" sx={{ fontWeight: 'bold' }}>{o.qualificador_nome}</Typography>
            <Typography variant="caption" component="div" color="text.secondary" >{!!o.quantificador_homem ? o.quantificador_homem : o.quantificador_maquina}</Typography>
          </D>
        )}
      </D>
    )
  }
}
class MediatecaEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      qualiOpts: {
        old: _.pluck(this.props.ocs, 'qualificador_nome'),
        new: _.difference(_.pluck(this.props.facts, 'qualificator'), _.pluck(this.props.ocs, 'qualificador_nome'))
      },
      qualificador: '',
      quantificador: '',
      toolSelected: 'old'
    }
  }
  handleInputChange = v => {
    //v = v.trim();
    //if(v.length > 0){
    this.setState({ quantificador: v });
  }
  handleConfirm = () => {
    if (!!this.props.docId && !!this.state.qualificador && !!this.state.quantificador) {
      var bReadyToGo = true;

      //Se for data, está escrito correto?   (início)
      var strType = (!!_.findWhere(this.props.ocs, { qualificador_nome: this.state.qualificador }) ?
                      _.findWhere(this.props.ocs, { qualificador_nome: this.state.qualificador }).tipo : 'string');
      let [day, month, year] = this.state.quantificador.split('/')
      var dtConversion = new Date(+year, +month - 1, +day)
      var bIsDate = (dtConversion instanceof Date && !isNaN(dtConversion))

      if(strType == "date" && !bIsDate){
        bReadyToGo = false
        this.props.alert('Data inválida')
      }
      //Se for data, está escrito correto?   (fim)
      //Não pode string em branco e vazio (início)
      var v = this.state.quantificador.trim()
      if(v.length == 0){
        bReadyToGo = false
        this.props.alert('Valor não pode ser branco ou vazio')
      }
      //Não pode string em branco e vazio (fim)

      if(bReadyToGo){
        this.props.api.post('mediateca/ocorrencias', {
          doc_id: this.props.docId,
          qualificador: _.findWhere(this.props.facts, { qualificator: this.state.qualificador }).id,
          qualificador_nome: this.state.qualificador,
          quantificador: this.state.quantificador.trim()
        }).then(r => {
             this.props.updateOcs()
             this.props.dialogClose()
             if (r.status === 'ok') {
               this.props.alert('Registro atualizado com sucesso')
               this.props.updateMediateca()
             } else {
               this.props.alert('Erro ao atualizar o registro')
             }
           })
      }
    } else {
      this.props.alert('Preencha todos os campos para confirmar')
    }
  }
  handleRemoveOccurrence = () => {
    this.props.api.post('mediateca/deleteoccurrence', {
      documento_id: this.props.docId,
      fato_id: _.findWhere(this.props.facts, { qualificator: this.state.qualificador }).id
    }).then(r => {
         this.props.updateOcs()
         this.props.dialogClose()
         if (r.status === 'ok') {
           this.props.alert('Registro removido com sucesso')
           this.props.updateMediateca()
         } else {
           this.props.alert('Erro ao remover o registro')
         }
       })
  }
  render() {
    return (
      <>
        <ConfirmDialog
          title="Editar Ocorrências do Documento"
          onCancel={this.props.dialogClose}
          onConfirm={this.handleConfirm}>
          <D h="1rem" />
          <ToggleButtonGroup
            color="primary"
            className="row w aF"
            style={{ minWidth: '15em' }}
            value={this.state.toolSelected}
            exclusive
            onChange={e => this.setState({ toolSelected: e.target.value, qualificador: '' })}>
            <ToggleButton className="e" value="old">Existente</ToggleButton>
            <ToggleButton className="e" value="new">Novo</ToggleButton>
          </ToggleButtonGroup>
          <CustomSelect
            label="Ocorrência"
            margin="normal"
            value={this.state.qualificador}
            options={this.state.qualiOpts[this.state.toolSelected]}
            onChange={v => this.setState({ qualificador: v })} />
          {!!this.state.qualificador &&
            <>
              {this.state.toolSelected === 'old' &&
                <D className="col w aC" style={{ padding: '1rem' }}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardHeader
                      titleTypographyProps={{variant:'subtitle2' }}
                      title="Valor identificado pela máquina"
                      subheader={!!_.findWhere(this.props.ocs, { qualificador_nome: this.state.qualificador }) ?
                          _.findWhere(this.props.ocs, { qualificador_nome: this.state.qualificador }).quantificador_maquina : ''}
                      action={
                              <Tooltip title="Excluir ocorrência" arrow>
                                <IconButton
                                  onClick={this.handleRemoveOccurrence}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                      }
                    />
                  </Card>
                </D>
              }
              <Input
                type="text"
                label="Valor Substituto"
                margin="normal"
                variant="outlined"
                value={this.state.quantificador}
                onChange={(e) => {this.handleInputChange(e)}} />
            </>
          }
          <D h="1rem" />
        </ConfirmDialog>
      </>
    )
  }

}
class MediatecaAccess extends React.Component {
  constructor(props) {
    super(props)
    this.updateList = this.updateList.bind(this)
    this.state = {
      open: {},
      lists: []
    }
  }
  handleClick = key => () => {
    this.setState({ [key]: !this.state[key] });
  }
  componentDidMount = () => this.updateList()
  updateList = () => this.props.api.get('/mediateca/docaccess/' + this.props.docId).then(r => { this.setState({ lists: r }) })
  render() {
    return (
      <MessageDialog
        title="Lista de acessos ao documento"
        onOk={this.props.dialogClose}
      >
        <D h="1rem" />
        <List component="div" >
          {this.state.lists.map(({ key, label, items }) => {
            const open = this.state[key] || false;
            return (
              <D key={key}>
                <ListItem button onClick={this.handleClick(key)}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText inset primary={label} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map(({ key: childKey, label: childLabel }) => (
                      <ListItem key={childKey} button sx={{ pl: 4 }} >
                        <ListItemIcon>
                          <DateRangeOutlined />
                        </ListItemIcon>
                        <ListItemText inset primary={childLabel} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </D>
            );
          })}
        </List>
        <D h="1rem" />
      </MessageDialog>
    )
  }
}
class MediatecaInfo extends React.Component {
  constructor(props) {
    super(props)
    this.updateOcs = this.updateOcs.bind(this)
    this.state = { ocs: null }
  }
  componentDidMount = () => this.updateOcs()
  updateOcs = () => this.props.api.get('/mediateca/ocorrencias/' + this.props.file.doc_id).then(r => this.setState({ ocs: r }))
  render() {
    return (
      <D className="col aF scD" size="300px" style={{ marginLeft: '1rem', padding: '8px', background: '#f2f6fc' }}>
        <D className="row w aF">
          <D e side img={C.baseURL + 'pic/' + this.props.file.thumb_png} />
          <D className="col aF">
            <Tooltip title="Editar" arrow>
              <Edit className="hv" color="action" onClick={() => this.props.dialog(
                <MediatecaEdit docId={this.props.file.doc_id} facts={this.props.facts} ocs={this.state.ocs} dialogClose={this.props.dialogClose} api={this.props.api} alert={this.props.alert} updateOcs={this.updateOcs} updateMediateca={this.props.updateMediateca}
                 />
              )} />
            </Tooltip>
            <D h="0.8rem" />
            <Tooltip title="Abrir painel de leitura" arrow>
              <Visibility className="hv" color="action" onClick={() => window.open('/viewer.html?p=' + this.props.file.doc_id, '_blank')} />
            </Tooltip>
            <D h="0.8rem" />
            <Tooltip title="Acessos ao documento" arrow>
              <SensorOccupied className="hv" color="action" onClick={() => {
                this.props.dialog(
                  <MediatecaAccess docId={this.props.file.doc_id} dialogClose={this.props.dialogClose} api={this.props.api} alert={this.props.alert} />
                )
              }} />
            </Tooltip>
            <D h="0.8rem" />
            <Tooltip title="Pedido de reparo" arrow>
              <Build className="hv" color="action" onClick={() => this.props.dialog(
                <PedidoReparo docId={this.props.file.doc_id} dialogClose={this.props.dialogClose} api={this.props.api} alert={this.props.alert} />
              )
              } />
            </Tooltip>
          </D>
          <D size="8px" />
          <D className="col aF">
            <Tooltip title="Fechar detalhes" arrow>
              <Close className="hv" color="action" onClick={this.props.closeInfo} />
            </Tooltip>
            <D h="0.8rem" />            
          </D>
        </D>
        <D h="1rem" />
        <D className="row w aC">
          <D className="row aC" size="24px">
            {this.props.file.st === 'processado' && <Tooltip title={'Arquivo ' + this.props.file.st} arrow><CheckCircleOutline color="action" /></Tooltip>}
            {this.props.file.st === 'em processamento' && <Tooltip title={'Arquivo ' + this.props.file.st} arrow><Sync color="action" /></Tooltip>}
            {this.props.file.st === 'não processado' && <Tooltip title={'Arquivo ' + this.props.file.st} arrow><SyncDisabledOutlined color="action" /></Tooltip>}
            {this.props.file.st === 'erro' && <Tooltip title={'Arquivo ' + this.props.file.st} arrow><DangerousOutlined color="action" /></Tooltip>}
          </D>
          <D size="8px" />
          <D className="col w aF scD th">
            <Typography variant="body2" component="div" className="e aF th">{this.props.file.nome}</Typography>
            <D className="row w aB">
              <Typography variant="caption" component="div" color="text.secondary">{moment(this.props.file.created_at).format('LL')}</Typography>
              <Typography variant="caption" component="div" color="text.secondary">{Math.round(Number(this.props.file.tamanho) / 1024).toString() + ' kb'}</Typography>
            </D>
          </D>
        </D>

        <D h="0.3rem" />
        <D className="col e aF scB" style={{ borderRadius: '5px', background: '#fff', padding: '8px' }}>
          <MediatecaQualificators key={this.props.file.doc_id} ocs={this.state.ocs} />
        </D>
      </D>
    )
  }
}
class MediatecaCard extends React.Component {
  render() {
    return (
      <Card component={D}
        className="col aF"
        style={{
          minWidth: '200px',
          maxWidth: '200px',
          minHeight: '170px',
          margin: '0.3rem',
          border: this.props.selectedRows.indexOf(this.props.file.doc_id) !== -1 ? '2px solid #3217d1' : 'none'
        }}
        onClick={() => {
          this.props.onClick(this.props.file.doc_id)
          this.props.getInfo(this.props.file.doc_id)
        }}>
        {!!this.props.file.thumb_png ?
          <D e cover h="70px" img={C.baseURL + 'pic/' + this.props.file.thumb_png} /> :
          <Skeleton animation="wave" height={100} variant="rectangular" />
        }
        <CardContent className="col e aF" component={D}>
          <Typography variant="body2" component="div" className="th" sx={{ fontWeight: this.props.file.rd_ctd === '0' ? 'bold' : 'normal' }}>{this.props.file.nome}</Typography>
          <D h="7px" />
          <D className="row w aB">
            <D className="col e aF">
              <Typography variant="caption" component="div" sx={{ fontWeight: this.props.file.rd_ctd === '0' ? 'bold' : 'normal' }} >{Math.round(Number(this.props.file.tamanho) / 1024).toString() + ' kb'}</Typography>
              <Typography variant="caption" component="div" sx={{ fontWeight: this.props.file.rd_ctd === '0' ? 'bold' : 'normal' }} >{moment(this.props.file.created_at).format('LL')}</Typography>
            </D>
            {this.props.file.st === 'processado' && <CheckCircleOutline color={this.props.file.rd_ctd === '0' ? "black" : "action"} />}
            {this.props.file.st === 'em processamento' && <Sync color={this.props.file.rd_ctd === '0' ? "black" : "action"} />}
            {this.props.file.st === 'não processado' && <SyncDisabledOutlined color={this.props.file.rd_ctd === '0' ? "black" : "action"} />}
            {this.props.file.st === 'erro' && <DangerousOutlined color={this.props.file.rd_ctd === '0' ? "black" : "action"} />}
          </D>
        </CardContent>
      </Card>
    )
  }
}
class MediatecaGrid extends React.Component {
  render() {
    return !!this.props.fls && typeof this.props.fls === 'object' &&
      <>
        <Divider />
        <D className="col e aF scB">
          <D h="1rem" />
          <D className="wrp aC">
            {this.props.fls.length > 0 && this.props.fls.map(f => {
              return checker(f.ss, this.props.searchField) ?
                <MediatecaCard key={f.thumb_png} file={f} getInfo={this.props.getInfo} onClick={this.props.onClick} selectedRows={this.props.selectedRows} /> : null
            })}
          </D>
          <D h="3rem" />
        </D>
        <Divider />
      </>
  }
}
class MediatecaList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: []
    }

  }
  headCells = [
    { id: 'Tipo', label: 'Tipo' },
    { id: 'Embarcacao', label: 'Embarcação' },
    { id: 'Status', label: 'Status' },
    { id: 'Data Aproximada', label: 'Data Aproximada' },
    { id: 'Nome', label: 'Nome' },
    { id: 'ID', label: 'ID' },
    { id: 'Tamanho', label: 'Tamanho' },
    { id: 'Criado em', label: 'Criado em' }
  ];


  render() {
    const createSortHandler = (property) => (event) => {
      const isAsc = (this.props.orderBy === property && this.props.order === 'asc')
      this.props.updateOrder_OrderBy((isAsc ? 'desc' : 'asc'), property)
      // this.props.sortDictionary( this.props.fls, property, (isAsc ? 'desc' : 'asc') )
    }
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ height: '100%' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TRow sx={{ height: '3rem', background: '#ccc' }}>
                <TCell />
                <TCell />
                {this.headCells.map((headCell) => (
                  <TCell key={headCell.id} sortDirection={this.props.orderBy === headCell.id ? this.props.order : false} >
                    <TSortLabel
                      active={this.props.orderBy === headCell.id}
                      direction={this.props.orderBy === headCell.id ? this.props.order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {this.props.orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {this.props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TSortLabel>
                  </TCell>
                ))}
              </TRow>
            </TableHead>
            <TableBody>
              {!!this.props.fls && typeof this.props.fls === 'object' &&
                <>
                  {this.props.fls.length > 0 && this.props.fls.map(f => {
                    return (
                      <TRow key={f.thumb_png} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TCell padding="checkbox">
                          <Checkbox checked={this.props.selectedRows.indexOf(f.doc_id) !== -1} onChange={() => this.props.onRowClick(f.doc_id)} />
                        </TCell>
                        <TCell>
                          <Tooltip title="Detalhes" arrow>
                            <IconButton onClick={() => this.props.getInfo(f.doc_id)} >
                              <InfoOutlined />
                            </IconButton>
                          </Tooltip>
                        </TCell>
                        <TCell component="th"><D className="th" style={{ maxWidth: '20rem', fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}   >{f.tipodoc}</D></TCell>
                        <TCell component="th"><D className="th" style={{ maxWidth: '20rem', fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}   >{f.fatoprincipal}</D></TCell>
                        <TCell component="th">
                          {f.st === 'processado' && <Tooltip title={f.st} arrow><CheckCircleOutline color={f.rd_ctd === '0' ? "black" : "action"} /></Tooltip>}
                          {f.st === 'em processamento' && <Tooltip title={f.st} arrow><Sync color={f.rd_ctd === '0' ? "black" : "action"} /></Tooltip>}
                          {f.st === 'não processado' && <Tooltip title={f.st} arrow><SyncDisabledOutlined color={f.rd_ctd === '0' ? "black" : "action"} /></Tooltip>}
                          {f.st === 'erro' && <Tooltip title={f.st} arrow><DangerousOutlined color={f.rd_ctd === '0' ? "black" : "action"} /></Tooltip>}
                        </TCell>
                        <TCell component="th" style={{ fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}>{moment(f.dtaprox, 'DD/MM/YYYY').format('LL')}</TCell>
                        <TCell component="th"><D className="th" style={{ maxWidth: '20rem', fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}  >{f.nome}</D></TCell>
                        <TCell component="th"><D className="th" style={{ fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}  >{f.doc_id}</D></TCell>
                        <TCell component="th" style={{ fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}>{Math.round(Number(f.tamanho) / 1024).toString() + ' kb'}</TCell>
                        <TCell component="th" style={{ fontWeight: f.rd_ctd === '0' ? 'bold' : 'normal' }}>{moment(f.created_at).format('LL')}</TCell>
                      </TRow>
                    )
                  })}
                  {this.props.fls.length === 0 &&
                    <TRow key={Math.random().toString()}>
                      <TCell component="th" align="center" colSpan={11}>Nenhum registro encontrado</TCell>
                    </TRow>
                  }
                </>
              }
              {!this.props.fls && <TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={11}><CircularProgress /></TCell></TRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}
class PedidoReparo extends React.Component {
  constructor(props) {
    //docId={this.props.file.doc_id} dialogClose={this.props.dialogClose} api={this.props.api} alert={this.props.alert}
    super(props)
    this.state = {
      codigo: ""
    }
  }
  componentDidMount = () => {
    this.props.api.get('/RepairOrder/' + this.props.docId).then(r => {
      if(r){
        this.setState({ codigo: r[0].codigo }) 
      }
    })
  }
  render() {
    return (
      <>
        <ConfirmDialog
          title="Pedido de Reparo"
          onConfirm={() => {
            if(this.state.codigo){ 
              this.props.api.put('/RepairOrder/' + this.props.docId + '/' + this.state.codigo).then(r => {})           
            } else {
              this.props.api.put('/RepairOrder/' + this.props.docId + '/null').then(r => {}) 
            }
            this.props.dialogClose()
          }}
          onCancel={this.props.dialogClose}
          >
          <D h="1rem" />
          <TextField
            type="number"
            label="Número do Pedido de Reparo"
            margin="normal"
            variant="outlined"
            value={this.state.codigo}
            onChange={v => this.setState({ codigo: v.target.value })} />
          <D h="1rem" />
        </ConfirmDialog>
      </>
    )
  }
}
export class Mediateca extends React.Component {
  constructor(props) {
    super(props)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.sendFile = this.sendFile.bind(this)
    this.updatePipe = this.updatePipe.bind(this)
    this.closeInfo = this.closeInfo.bind(this)
    this.getInfo = this.getInfo.bind(this)
    this.changeTimeInterval = this.changeTimeInterval.bind(this)
    this.updateOrder_OrderBy = this.updateOrder_OrderBy.bind(this)
    this.sortDictionary = this.sortDictionary.bind(this)
    this.state = {
      aproxIniDateMenu: null,
      aproxFimDateMenu: null,
      attWordMenu: null,
      groupsMenu: null,
      uploadExpanded: true,
      selectedRows: [],
      searchField: '',
      filesToUpload: null,
      viewType: 'list',
      infoView: null,
      rawFls: [],
      rawOcs: [],
      fls: null,
      attWords: [],
      selectedAttWords: [],
      facts: [],
      selectedAproxIniDate: '',
      selectedAproxFimDate: '',
      order: 'desc', // 'desc' or 'asc'
      orderBy: 'Data Aproximada', // or other key from <TCell> 
      page: 1,
      pageSize: 25,
      count: 1198,
      limit: 48,
      data: [],
      groups: [],
      selectGroups: [],
      selectCurrentAtt: [],
      clickDate: false,
      gruposInfo: [],
      grupoIds: [],
      selectEmbarcacoes: [],
      selectCurrentEmbarcacoes: [],
      OrderBy2: []
    }
    this.pipe = []
    this.interval = null
  };


  getMediatecaPayload = async () => {
    const payload = await this.props.api.get(`mediateca/todos?page=${this.state.page}&pageSize=${this.state.pageSize}&dataInicio=${this.state.selectedAproxIniDate}&dataFim=${this.state.selectedAproxFimDate}&palavrasDeAtencao=${JSON.stringify(this.state.selectedAttWords)}&searching=${this.prepareSearchValues()}&orderBy=${JSON.stringify([this.state.orderBy, this.state.order])}&grupo=${JSON.stringify(this.state.selectGroups)}
    `);
    let limit = Math.ceil((payload.count) / this.state.pageSize);
    this.setState({ limit: limit, count: payload.count, fls: payload.data });
  };

  getPropsChange = async () => {
    const data = await this.props.api.get(`/users/selectGroupsandAttWord?user_id=${this.props.user.id}`);
    return data[0]
  };

  prepareSearchValues() {
    if (this.state.searchField.length > 0) {
    let searchValues = [this.state.searchField.trim()];
     searchValues = searchValues[0].split(' ');
    return JSON.stringify(searchValues) 
  } else {
      return []
  }
  };

  getGroupList = async () => {
    let grupos = []
    const data = await this.props.api.get('/Grupos')
    data.forEach(element => {
      grupos.push(element.nome)
    });
    this.setState({ groups: grupos })
    this.setState({ gruposInfo: data })
  };

  getEmbarcacoes = async () => {
    const data = await this.props.api.get(`/Grupo/Embarcacoes?selectGroups=${JSON.stringify(this.state.grupoIds)}`)
    const embarcacoes = data.map(selectEmbarcacoes => selectEmbarcacoes.nome)
    return embarcacoes;
  };

  changeSelectAttA = async () => {
    await this.props.api.post(`/user/selectGroupsAndAttWord?selectAttword=${JSON.stringify(this.state.selectedAttWords)}&user_id=${this.props.user.id}&selectGroups=${JSON.stringify(this.state.selectGroups)}&selectEmbarcacoes=${this.state.selectEmbarcacoes}&selectGroupsId=${this.state.grupoIds}`)
  };

  cleanFilter = () => {
    this.setState({
      selectedAttWords: [],
      selectGroups: [],
      selectEmbarcacoes: [],
      grupoIds: [],
      selectedAproxIniDate: '',
      selectedAproxFimDate: '',
      searchField: '', page: 1
    }, () => {
      this.changeSelectAttA().then(() => {
        this.getMediatecaPayload()
      })
    })
  };

  componentDidMount = (prevProps, prevState) => {
    this.props.api.get('/AttWords').then(r => this.setState({ attWords: _.pluck(r, 'texto') }))
    this.props.api.get('/Facts').then(r => this.setState({ facts: r }))
    this.getGroupList()
    this.changeTimeInterval(59)
    this.getPropsChange().then((data) => {  
      this.setState({
        selectedAttWords: data.palavras ?? [],
        selectGroups: data.grupos ?? [],
        selectEmbarcacoes: data.selectembarcacoes ?? [],
        grupoIds: data.gruposid ?? []
      }, () => {
        this.getMediatecaPayload();
      })
    })

	this.viewAlertDoc()
  };

  componentWillUnmount = () => clearInterval(this.interval)
  changeTimeInterval = t => {
    clearInterval(this.interval)
    this.interval = setInterval(() => this.updatePipe(), t * 1000)
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedAttWords !== prevState.selectedAttWords) {
      this.props.api.post(`/user/selectGroupsAndAttWord?selectAttword=${JSON.stringify(this.state.selectedAttWords)}&user_id=${this.props.user.id}&selectGroups=${JSON.stringify(this.state.selectGroups)}&selectEmbarcacoes=${JSON.stringify(this.state.selectEmbarcacoes)}&selectGroupsId=${JSON.stringify(this.state.grupoIds)} `).then(() => {
      })
    }
    if (this.state.grupoIds !== prevState.grupoIds) {
      this.getEmbarcacoes().then((embacacoes) => {
        this.setState({
          selectEmbarcacoes: embacacoes
        }, () => {
          this.props.api.post(`/user/selectGroupsAndAttWord?selectAttword=${JSON.stringify(this.state.selectedAttWords)}&user_id=${this.props.user.id}&selectGroups=${JSON.stringify(this.state.selectGroups)}&selectEmbarcacoes=${JSON.stringify(this.state.selectEmbarcacoes)}&selectGroupsId=${JSON.stringify(this.state.grupoIds)}`)
        });
      });
    }
    if (this.state.page !== prevState.page) {
      this.getMediatecaPayload()
    } else if ((this.state.pageSize !== prevState.pageSize)
      || (this.state.orderBy !== prevState.orderBy)
      || (this.state.order !== prevState.order)
    ) {
      this.setState({ page: 1 }, () => {
        this.getMediatecaPayload()
      })
    }
  };

  sendFile = i => {
    const data = new FormData()
    data.append('file', this.pipe[i].file, this.pipe[i].file.name)
    if(this.pipe[i].file.name.toLowerCase().includes(".pdf")){
        const pdfjs = global.pdfjsLib.getDocument(URL.createObjectURL(this.pipe[i].file))
        const canvas = document.getElementById('canvas')
        pdfjs.promise.then(pdf => {
            pdf.getPage(1).then(page => {
                const viewport = page.getViewport({ scale: 1 })
                canvas.height = viewport.height
                canvas.width = viewport.width
                const renderContext = { canvasContext: canvas.getContext('2d'), viewport: viewport }
                page.render(renderContext).promise.then(() => {
                    fetch(canvas.toDataURL("image/png")).then(res => res.blob()).then(blob => {
                        const file = new File([blob], 'thumb.png', { type: 'image/png' })
console.log(file)
                        data.append('thumb', file, 'thumb.png')
                        this.props.api.post('/mediateca/upload', data).then(r => {
                            this.pipe[i].createdAt = new Date()
                            this.pipe[i].status = 'Done'
                            this.updatePipe()
                            pdfjs.destroy()
                        })
                    })
                })
            })
        }, r => {
            this.pipe[i].createdAt = new Date()
            this.pipe[i].status = 'Error'
            this.updatePipe()
            pdfjs.destroy()
        })
    }else{ //file different from pdf
        fetch(C.navImg).then(r => r.blob()).then(blob => {
            data.append('thumb', blob , 'thumb.png')
            this.props.api.post('/mediateca/upload', data).then(r => {
                this.pipe[i].createdAt = new Date()
                    this.pipe[i].status = 'Done'
                    this.updatePipe()
                })
        })
    }
  };

  updatePipe = () => {
    let pp = []
    let file2up = -1
    if (this.pipe.length > 0) {
      for (let i = 0; i < this.pipe.length; i++) {
        if (this.pipe[i].status !== 'Done' || this.pipe[i].status !== 'Error') {
          if (file2up < 0) {
            if (this.pipe[i].status === 'Uploading') {
              file2up = i
            } else if (this.pipe[i].status === 'Pending') {
              this.pipe[i].status = 'Uploading'
              file2up = i
              this.sendFile(i)
            }
          }
        }
        if (!(moment().isAfter(moment(this.pipe[i].createdAt).add(10, 'seconds')) && (this.pipe[i].status === 'Done' || this.pipe[i].status === 'Error'))) {
          pp.push(<UploadListCard
            key={Math.random().toString()}
            title={this.pipe[i].file.name}
            status={this.pipe[i].status} />)
        }
      }
    }
    this.setState({ filesToUpload: pp.length === 0 ? null : pp })
  };

  uploadFiles = () => {
    let fls = Array.from(document.getElementById('inputfiles').files)
    fls.forEach(f => this.pipe.push({ file: f, status: 'Pending', createdAt: new Date() }))
    _.sortBy(this.pipe, 'createdAt')
    this.updatePipe()
  };

  getInfo = i => {
    this.setState({
      infoView: <MediatecaInfo
        key={Math.random().toString()}
        file={_.find(this.state.fls, { doc_id: i })}
        facts={this.state.facts}
        closeInfo={this.closeInfo}
        dialog={this.props.dialog}
        dialogClose={this.props.dialogClose}
        alert={this.props.alert}
        api={this.props.api}
        updateMediateca={this.getMediatecaPayload} />,
      selectedRows: [i]
    })
  };

  onRowClick = i => {
    this.setState({
      selectedRows: this.state.selectedRows.indexOf(i) > -1 ? _.without(this.state.selectedRows, i) : _.union(this.state.selectedRows, [i])
    })
  };

  closeInfo = () => this.setState({ infoView: null, selectedRows: this.state.viewType === 'list' ? this.state.selectedRows : [] })
  updateOrder_OrderBy = (o, b) => {
    this.setState({ order: o, orderBy: b })
  };

  sortDictionary(dataDic, orderBy, order) {
    dataDic.sort(function (a, b) {
      if (order === 'asc') {
        if (a[orderBy] === null)
          return 1
        if (b[orderBy] === null)
          return -1
        return (a[orderBy] > b[orderBy]) ? 1 : ((a[orderBy] < b[orderBy]) ? -1 : 0)
      } else {
        if (b[orderBy] === null)
          return 1
        if (a[orderBy] === null)
          return -1
        return (b[orderBy] > a[orderBy]) ? 1 : ((b[orderBy] < a[orderBy]) ? -1 : 0)
      }
    });
  };

  handleChangePageSize = (event) => {
    this.setState({ pageSize: event.target.value })
  };

  //Este trecho trata a tentativa de visualização de um PDF
  viewAlertDoc = () => {
    const urlquerystringparams = new URLSearchParams(window.location.search)  
    const alertdoc = urlquerystringparams.get('alertdoc')
    if(alertdoc){ // A URL Query String Param tem informação adicional pedindo para abrir um PDF
      this.props.api.get('/mediateca/ocorrencias/' + alertdoc).then(r => true)
      window.open('/viewer.html?p=' + alertdoc, '_blank')
    }
    else{ // Nada demais, nada a ser feito
    }
  };

  render() {
    const handleChange = (e, p) => {
      this.setState({ page: p });
    };
    return (
      <D className="col e aF scD" >
        <Box className="col e aF scD" style={{ padding: '1rem 2rem' }}>
          <D className="r2c w aC pa">
            <D className="row w e aC">
              <SearchFieldMediateca value={this.state.searchField} onChange={value => this.setState({ searchField: value })} />
              <Tooltip title='Pesquisar' arrow>
                <IconButton sx={{ marginLeft: '12px' }} onClick={ () => this.setState({page: 1}, () => this.getMediatecaPayload())}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </D>
            <Divider sx={{ paddingRight: '5px' }} orientation="vertical" flexItem />
            <D size="0.5rem" />
            <D className="row w aC" p="2">
              <D className="row aC" style={{
                background: '#eeeeee',
                borderRadius: '0.5rem',
              }}>
                <Tooltip title={'Data inicial ' + (!!this.state.selectedAproxIniDate ? moment(this.state.selectedAproxIniDate).format('D/M/YY') : '')} arrow>
                  <IconButton
                    onClick={e => this.setState({ aproxIniDateMenu: e.currentTarget })}
                    color={!!this.state.selectedAproxIniDate ? 'primary' : 'default'}
                    aria-controls={!!this.state.aproxIniDateMenu ? 'aproxDateMenu' : undefined}
                    aria-expanded={!!this.state.aproxIniDateMenu ? 'true' : undefined}>
                    <DateRangeOutlined />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={this.state.aproxIniDateMenu}
                  id="aproxIniDateMenu"
                  open={!!this.state.aproxIniDateMenu}
                  onClose={() => this.setState({ aproxIniDateMenu: false })}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      value={this.state.selectedAproxIniDate}
                      onChange={v => this.setState({ selectedAproxIniDate: moment(v).format('YYYY-MM-DD') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        this.setState({ selectedAproxIniDate: '', page: 1 }, () => {this.getMediatecaPayload()})}>Limpar data</Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.setState({page: 1}, () => this.getMediatecaPayload())}>Aplicar</Button>
                  </DialogActions>
                </Menu>
                <D size="0.5rem" />
                <Tooltip title={'Data Fim ' + (!!this.state.selectedAproxFimDate ? moment(this.state.selectedAproxFimDate).format('D/M/YY') : '')} arrow>
                  <IconButton
                    onClick={e => this.setState({ aproxFimDateMenu: e.currentTarget })}
                    color={!!this.state.selectedAproxFimDate ? 'primary' : 'default'}
                    aria-controls={!!this.state.aproxFimDateMenu ? 'aproxDateMenu' : undefined}
                    aria-expanded={!!this.state.aproxFimDateMenu ? 'true' : undefined}>
                    <DateRangeOutlined />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={this.state.aproxFimDateMenu}
                  id="aproxFimDateMenu"
                  open={!!this.state.aproxFimDateMenu}
                  onClose={() => this.setState({ aproxFimDateMenu: false })}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      value={this.state.selectedAproxFimDate}
                      onChange={v => this.setState({ selectedAproxFimDate: moment(v).format('YYYY-MM-DD') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        this.setState({ selectedAproxFimDate: '', page: 1 }, () => {this.getMediatecaPayload()})}>Limpar data</Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.setState({page: 1}, () => this.getMediatecaPayload())}>Aplicar</Button>
                  </DialogActions>
                </Menu>
              </D>
              <D h="1rem" size="0.5rem" />
              <Tooltip title="Palavras de atenção" arrow>
                <IconButton
                  onClick={e => this.setState({ attWordMenu: e.currentTarget })}
                  color={!!this.state.selectedAttWords.length > 0 ? 'primary' : 'default'}
                  aria-controls={!!this.state.attWordMenu ? 'attWordMenu' : undefined}
                  aria-expanded={!!this.state.attWordMenu ? 'true' : undefined}>
                  <ErrorOutline />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={this.state.attWordMenu}
                id="attWordMenu"
                open={!!this.state.attWordMenu}
                onClose={() => this.setState({ attWordMenu: null })}>
                <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                  {this.state.attWords.map((a) => {
                    const isChecked = this.state.selectedAttWords.includes(a);
                    const handleChange = (event) => {
                      const checked = event.target.checked;
                      let updatedSelectedAttWords = [];
                      if (checked) {
                        updatedSelectedAttWords = _.union(this.state.selectedAttWords, [a]);
                      } else {
                        updatedSelectedAttWords = _.without(this.state.selectedAttWords, a);
                      }
                      this.setState({ selectedAttWords: updatedSelectedAttWords });
                    };
                    return (
                      <D className="row aC" key={a}>
                        <Checkbox checked={isChecked} onChange={handleChange} />
                        <D e>{a}</D>
                      </D>
                    );
                  })}
                </D>
              </Menu>
              <D size="0.5rem" />
              <Tooltip title={C.groupLabel} arrow>
                <IconButton
                  onClick={e => this.setState({ groupsMenu: e.currentTarget })}
                  color={!!this.state.selectGroups.length > 0 ? 'primary' : 'default'}
                  aria-controls={!!this.state.groupsMenu ? 'attWordMenu' : undefined}
                  aria-expanded={!!this.state.groupsMenu ? 'true' : undefined}>
                  <SouthAmericaIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={this.state.groupsMenu}
                id="groupsMenu"
                open={!!this.state.groupsMenu}
                onClose={() => this.setState({ groupsMenu: null })}>
                <D className="col e aF scD scB hv" style={{ maxHeight: '30vh', padding: '0.3rem 1rem 0.3rem 0.3rem' }}>
                  {this.state.groups.map((a) => {
                    const isChecked = this.state.selectGroups.includes(a);
                    const handleChange = (event) => {
                      const checked = event.target.checked;
                      let updatedSelectGroups = [];
                      let gruposId = []
                      if (checked) {
                        updatedSelectGroups = _.union(this.state.selectGroups, [a]);
                        updatedSelectGroups.forEach(element => {
                          let equalGrupos = this.state.gruposInfo.find(value => value.nome === element)
                          gruposId.push(equalGrupos.id)
                        });

                      } else {
                        updatedSelectGroups = _.without(this.state.selectGroups, a);
                        updatedSelectGroups.forEach(element => {
                          let equalGrupos = this.state.gruposInfo.find(value => value.nome === element)
                          gruposId.push(equalGrupos.id)
                        });
                      }
                      this.setState({ selectGroups: updatedSelectGroups, grupoIds: gruposId });
                    };
                    return (
                      <D className="row aC" key={a}>
                        <Checkbox checked={isChecked} onChange={handleChange} />
                        <D e>{a}</D>
                      </D>
                    );
                  })}
                </D>
              </Menu>
              <D size="0.5rem" />
              <Tooltip title="Limpar Filtros" arrow>
                <IconButton onClick={() => { this.cleanFilter() }}>
                  <FilterAltOffIcon color='action' />
                </IconButton>
              </Tooltip>
              <D e />
              <D size="0.5rem" />
              {this.state.selectedRows.length > 0 &&
                <>
                  {this.props.permission === 'w' &&
                    <Tooltip title="Excluir selecionados" arrow>
                      <IconButton
                        // color="error"
                        onClick={() => this.props.dialog(
                          <ConfirmDialog
                            title="Excluir Documentos"
                            msg="Ao confirmar, os registros serão excluídos e não poderão ser recuperados"
                            reg={this.state.selectedRows.length > 1 ? (this.state.selectedRows.length + ' registros selecionados') : '1 registro selecionado'}
                            onCancel={this.props.dialogClose}
                            onConfirm={() => {
                              const dcs = (!!this.state.selectedRows && this.state.selectedRows.length === 0) ? this.state.fls.map(f => f.doc_id) : this.state.selectedRows
                              this.props.api.post('mediateca/delete', { docs: dcs }).then(r => {
                                this.setState({ selectedRows: [] })
                                this.props.dialogClose()
                                this.props.alert('Documentos excluídos com sucesso')
                                this.getMediatecaPayload()
                              })
                            }} />
                        )}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  }
                  <D size="0.5rem" />
                </>
              }
              {this.props.permission === 'w' &&
                <Tooltip title="Reprocessar documentos" arrow>
                  <IconButton
                    onClick={() => this.props.dialog(
                      <ConfirmDialog
                        title="Reprocessar Documentos"
                        msg={this.state.selectedRows.length > 0 ? 'Ao confirmar, os metadados dos documentos selecionados serão substituídos' : 'Nenhum documento selecionado'}
                        reg={this.state.selectedRows.length > 0 ? (this.state.selectedRows.length > 1 ? (this.state.selectedRows.length + ' registros selecionados') : '1 registro selecionado') : 'Tarefa demorada. Reprocessar TODOS?'}
                        onCancel={this.props.dialogClose}
                        onConfirm={() => {
                          const dcs = (!!this.state.selectedRows && this.state.selectedRows.length === 0) ? this.state.fls.map(f => f.doc_id) : this.state.selectedRows
                          this.props.api.post('mediateca/reload', { docs: dcs }).then(r => {
                            this.setState({ selectedRows: [] })
                            this.props.dialogClose()
                            this.props.alert('Solicitação de reprocessamento enviada com sucesso')
                            this.getMediatecaPayload()
                          })
                        }} />
                    )}>
                    <ModelTraining />
                  </IconButton>
                </Tooltip>
              }
              <D size="0.5rem" />
              {this.props.permission === 'w' &&
                <>
                  <Tooltip title="Enviar documentos" arrow>
                    <IconButton
                      onClick={() => document.getElementById('inputfiles').click()}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                  <D size="0.5rem" />
                </>
              }
              {this.state.selectedRows.length > 0 &&
                <>
                  <Tooltip title="Baixar arquivos" arrow>
                    <IconButton
                      onClick={() => {
                        const dcs = (!!this.state.selectedRows && this.state.selectedRows.length === 0) ? this.state.fls.map(f => f.doc_id) : this.state.selectedRows
                        this.props.api.post('/mediateca/downloadzipfile', { docs: dcs }, { responseType: 'arraybuffer' }).then(response => {
                          this.setState({ selectedRows: [] })
                          const blob = new Blob([response], { type: 'application/octet-stream' })
                          saveAs(blob, "selectedFiles.zip")
                        })

                      }}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                  <D size="0.5rem" />
                </>
              }
              <Tooltip title="Trocar modo de exibição" arrow>
                <IconButton
                  onClick={() => this.setState({ viewType: this.state.viewType === 'icons' ? 'list' : 'icons', selectedRows: [] })}>
                  {this.state.viewType === 'list' && <GridView />}
                  {this.state.viewType === 'icons' && <ViewList />}
                </IconButton>
              </Tooltip>
            </D>
          </D>
          <D h="1rem" />
          <D className="row e aF scD">
            {this.state.viewType === 'list' &&
              <>
                <MediatecaList
                  fls={this.state.fls}
                  api={this.props.api}
                  getMediatecaPayload={this.getMediatecaPayload}
                  selectedRows={this.state.selectedRows}
                  searchField={this.state.searchField.toLowerCase()}
                  getInfo={this.getInfo}
                  onRowClick={this.onRowClick}
                  permission={this.props.permission}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  updateOrder_OrderBy={this.updateOrder_OrderBy}
                  sortDictionary={this.sortDictionary}
                />
              </>
            }
            {this.state.viewType !== 'list' &&
              <MediatecaGrid
                fls={this.state.fls}
                getInfo={this.getInfo}
                selectedRows={this.state.selectedRows}
                onClick={i => this.setState({ selectedRows: [i] })}
              />
            }
            {this.state.infoView}
          </D>
        </Box >
        <Box sx={{ display: 'flex', justifyContent: 'end', marginRight: '15px', marginTop: '5px' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label" >Registros</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={this.state.pageSize}
              label="Registros"
              onChange={this.handleChangePageSize}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Pagination sx={{ paddingTop: '10px' }}
            count={this.state.limit}
            size="medium"
            page={this.state.page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange} />
        </Box>
        {!!this.state.filesToUpload && this.state.filesToUpload.length > 0 &&
          <D col style={{ zIndex: '1051', position: 'fixed', bottom: '1rem', marginLeft: '1rem', minWidth: '200px', width: '100%', maxWidth: '350px' }}>
            <Paper className="col e aF" elevation={2}>
              <Accordion expanded={this.state.uploadExpanded} onClick={() => this.setState({ uploadExpanded: !this.state.uploadExpanded })}
                sx={{ boxShadow: 0, backgroundColor: '#fff', '::before': { backgroundColor: 'transparent' } }}>
                <AccordionSummary sx={{ backgroundColor: '#3C4252', borderRadius: '4px 4px 0px 0px' }} expandIcon={<ExpandLess sx={{ color: '#ccc' }} />}>
                  <Typography sx={{ color: '#fff' }}>Upload de documentos</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails className="col aF scY" style={{ minHeight: '300px', maxHeight: '300px' }}>{this.state.filesToUpload}</AccordionDetails>
              </Accordion>
            </Paper>
          </D>
        }
        <input className="hide" type="file" name="file" id="inputfiles" multiple="multiple" onChange={this.uploadFiles} />
        <canvas className="hide" id='canvas' />
      </D >
    )
  }
}
