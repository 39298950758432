import React from 'react'
import Formsy from 'formsy-react'
import { Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { D, Loading, TRow, TCell, TextFieldFormsy } from '../include'
class EditSysConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: this.props.row.name, path: this.props.row.path }
  }
  render() {
    return (
      <Formsy onValidSubmit={() => null} >
        <DialogTitle>Editar configuração do sistema</DialogTitle>
        <DialogContent>
          <DialogContentText className="row w aC">Altere os parâmetros que desejar e clique em salvar</DialogContentText>
          <D h="2rem" />
          <TextFieldFormsy defaultValue={this.props.row.name} value={this.state.name} onChange={v => this.setState({ name: v })} type="text" name="name" label="Nome"  required />
          <D h="1rem" />
          <TextFieldFormsy defaultValue={this.props.row.path} value={this.state.path} onChange={v => this.setState({ path: v })} type="text" name="path" label="Diretório"  required />
          <D h="2rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.put('/PathList/' + this.props.row.id, { id: this.props.row.id, name: this.state.name, path: this.state.path }).then(r => {
            this.props.dialogClose()
            this.props.alert(r.message)
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o perfil'))}>Salvar</Button>
        </DialogActions>
      </Formsy>
    )
  }
}
export class SysConfig extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/PathList').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<EditSysConfig row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Edit />
            </IconButton>
          </TCell>
        }
        <TCell component="th">{row.path}</TCell>
        <TCell component="th">{row.name}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}>Nenhuma permissão encontrada</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  <TCell>Diretório</TCell>
                  <TCell width="100%">Nome</TCell>
                </TRow>
              </TableHead>
              <TableBody>{this.state.rows}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
