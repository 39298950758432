import React from 'react'
import Formsy from 'formsy-react'
import { Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { D, Loading, SearchField, TRow, TCell, TextFieldFormsy, CustomSelect } from '../include'
class NewPermission extends React.Component {
  constructor(props) {
    super(props)
    this.state = { category: '', name: '', description: '' }
  }
  render() {
    return (
      <Formsy onValidSubmit={() => null} >
        <DialogTitle>Nova permissão de acesso</DialogTitle>
        <DialogContent>
          <TextFieldFormsy value={this.state.category} onChange={v => this.setState({ category: v })} type="text" name="category" label="Permissão"  required />
          <CustomSelect label="Tipo" options={['Ler', 'Escrever']} value={this.state.name} onChange={v => this.setState({ name: v })} />
          <TextFieldFormsy rows={5} value={this.state.description} onChange={v => this.setState({ description: v })} type="text" name="description" label="Descrição"  required />
          {/* <D h="2rem" /> */}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => (!this.state.category && !this.state.name && !this.state.description) ?
            this.props.alert('Preencha todos os campos') :
            this.props.api.post('/Permissions', { category: this.state.category, name: this.state.name, description: this.state.description }).then(r => {
              this.props.dialogClose()
              this.props.alert(r.message)
            }).catch(e => this.props.alert('Ocorreu um erro ao excluir o registro'))}>Salvar</Button>
        </DialogActions>
      </Formsy>
    )
  }
}
class ConfirmPermDelete extends React.Component {
  render() {
    return (
      <>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir a permissão de acesso:</DialogContentText>
          <D h="1rem" />
          <DialogContentText align="center" variant="h6">{this.props.row.id + ' - ' + this.props.row.category + '-' + this.props.row.name}</DialogContentText>
          <D h="1rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.delete('/Permission/' + this.props.row.id).then(r => {
            this.props.dialogClose()
            this.props.alert(r.message)
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o registro'))}>Confirmar</Button>
        </DialogActions>
      </>
    )
  }
}
export class Permissions extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { searchField: '', dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  }
  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Permissions').then(r => this.updateTable(r))
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' && <TCell align="center" component="th">
          <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<ConfirmPermDelete row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
            this.updateData()
            this.props.dialogClose()
          }} />)}>
            <Delete />
          </IconButton>
        </TCell>}
        <TCell component="th">{row.id}</TCell>
        <TCell component="th">{row.category}</TCell>
        <TCell component="th">{row.name}</TCell>
        <TCell component="th">{row.description}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}>Nenhuma permissão encontrada</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e />
              <Button variant="contained" color="primary" onClick={() => this.props.dialog(<NewPermission api={this.props.api} alert={this.props.alert} dialogClose={() => {
                this.updateData()
                this.props.dialogClose()
              }} />)}><Add />Permissão</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow sx={{ height: '3rem', background: '#ccc' }}>
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell>ID</TCell>
                  <TCell>Permissão</TCell>
                  <TCell>Tipo</TCell>
                  <TCell width="100%">Descrição</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().replace(' ', '').includes(this.state.searchField.toLowerCase().replace(' ', ''))) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
