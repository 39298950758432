import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Menu, MenuItem, AppBar, Toolbar, IconButton, Typography, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ExpandMore, MenuOutlined, Settings, Logout, Api, AccountCircle } from '@mui/icons-material'
import { D, C, openLink } from '../include'
import { navigation } from '../navigation'
class NoPage extends React.Component {
  render() {
    return (
      <div className="row e aC fullH aF">
        <Link to="/">
          <div className="row aC pa">Página não encontrada</div>
        </Link>
      </div>
    )
  }
}
class SideBar extends React.Component {
  render() {
    return (
      <>
        <Toolbar sx={{ backgroundColor: '#3C4252' }}>
          <D className="row e aC">
            <D className="row e aC">
              <img alt="" src={C.navImg} style={{ maxHeight: '28px' }} />
              <Typography variant="subtitle1" component="div" sx={{ color: '#fff', flexGrow: 1 }}>Wilson Sons</Typography>
            </D>
            <IconButton size="large" edge="end" color="inherit" aria-label="fechar" onClick={() => this.props.closeMenu()}>
              <MenuOutlined style={{ color: '#fff' }} />
            </IconButton>
          </D>
        </Toolbar>
        <Divider />
        <List className="col e aF scY">
          {navigation.map((n, i) => {
            if (!!this.props.user.permissions && !!this.props.user.permissions.find(v => v === n.permission)) {
              if (n.type === 'item') {
                return (
                  <Link key={n.id} to={n.url}
                    onClick={() => this.props.changeView(n.url)}>
                    <ListItem button>
                      <ListItemIcon>{n.icon}</ListItemIcon>
                      <ListItemText primary={n.title} sx={{ color: '#fff' }} />
                    </ListItem>
                  </Link>
                )
              } else {
                if (n.type === 'collapse') {
                  return (
                    <Accordion key={n.id} sx={{ boxShadow: 0, backgroundColor: 'transparent', '::before': { backgroundColor: 'transparent' } }}>
                      <AccordionSummary expandIcon={<ExpandMore sx={{ color: '#ccc' }} />}>
                        <ListItemIcon>{n.icon}</ListItemIcon>
                        <Typography sx={{ color: '#fff' }}>{n.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {n.children.map((c, j) => {
                          if (!!this.props.user.permissions && !!this.props.user.permissions.find(v => v === c.permission)) {
                            if (c.type === 'item') {
                              return (
                                <Link key={c.id} to={c.url}
                                  onClick={() => this.props.changeView(c.url)}>
                                  <ListItem button key={c.id}>
                                    <ListItemIcon>{c.icon}</ListItemIcon>
                                    <ListItemText primary={c.title} sx={{ color: '#fff' }} />
                                  </ListItem>
                                </Link>
                              )
                            } else {
                              return null
                            }
                          } else {
                            return null
                          }
                        }
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                } else {
                  return null
                }
              }
            } else {
              return null
            }
          })}
        </List>
        <D className="row w aC hv"
          style={{ minHeight: '75px', maxHeight: '75px', color: '#fff' }}
          onClick={() => openLink('http://ai2bizlab.com.br')}>
          <b>{C.appName}</b>
          <D style={{ marginLeft: '7px' }}>by</D>
          <img style={{ maxHeight: '24px', marginLeft: '7px' }} src="/static/ai2_200.png" alt="" />
          <D style={{ marginLeft: '7px' }}>Business</D>
        </D>
      </>
    )
  }
}
export class MainPage extends React.Component {
  constructor(props) {
    super(props)
    this.changeView = this.changeView.bind(this)
    this.state = {
      menuOpen: true,
      menuOpenSM: false,
      profileMenu: null,
      view: {},
      defaultPage: []
    }
    this.api = this.props.api
  }
  changeView = u => {
    let vw = {
      id: 'nopage',
      title: 'Página não encontrada',
      type: 'route',
      icon: null,
      component: <NoPage />,
      url: '/nopage',
      permission: ''
    }
    for (const v of Object.values(navigation)) {
      if (v.type !== 'collapse' && v.url === u) {
        if (!!this.props.user.permissions && !!this.props.user.permissions.find(p => p === v.permission)) {
          vw = v
        }
      } else if (v.type === 'collapse') {
        for (const v2 of Object.values(v.children)) {
          if (v2.type !== 'collapse' && v2.url === u) {
            if (!!this.props.user.permissions && !!this.props.user.permissions.find(p => p === v2.permission)) {
              vw = v2
            }
          }
        }
      }
    }
    vw.perm = (!!vw.permission && !!this.props.user.permissions && !!this.props.user.permissions.find(p => p === vw.permission.replace('-Ler', '-Escrever'))) ? 'w' : 'r'
    this.setState({ view: vw, menuOpenSM: false })
  }
  componentDidMount = () => {
    if (window.location.pathname === '/') {
      window.history.replaceState(window.history.state, 'Mediateca', '/mediateca')
      this.changeView('/mediateca')
    } else {
      this.changeView(window.location.pathname)
    }
  }
  render() {
    return (
      <D className="row e aF scD">
        <D className="hide-md">
          <Drawer sx={!!this.state.menuOpen ? { width: '300px', minHeight: '0px', minWidth: '0px', '& .MuiDrawer-paper': { backgroundColor: '#424242', width: '300px' } } : {}}
            variant="persistent" ModalProps={{ keepMounted: true }} anchor="left" open={this.state.menuOpen}>
            <SideBar user={this.props.user} menuOpen={this.state.menuOpen} closeMenu={() => this.setState({ menuOpen: false })} changeView={this.changeView} />
          </Drawer>
        </D>
        <D className="hide unhide-md">
          <Drawer sx={{ minHeight: '0px', minWidth: '0px', '& .MuiDrawer-paper': { backgroundColor: '#424242' } }}
            anchor="left" open={this.state.menuOpenSM} onClose={() => this.setState({ menuOpenSM: false })}>
            <SideBar user={this.props.user} menuOpen={this.state.menuOpenSM} closeMenu={() => this.setState({ menuOpenSM: false })} changeView={this.changeView} />
          </Drawer>
        </D>
        <D className="col e aF scD">
          <AppBar position="static">
            <Toolbar sx={{ backgroundColor: '#3C4252' }}>
              {!this.state.menuOpen &&
                <D className="row aC hide-md">
                  <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => this.setState({ menuOpen: true })}>
                    <MenuOutlined />
                  </IconButton>
                  <img alt="" src={C.navImg} style={{ maxHeight: '28px' }} />
                  <Typography variant="subtitle1" component="div" sx={{ color: '#fff', flexGrow: 1 }}>Wilson Sons</Typography>
                  <D style={{ marginLeft: '35px' }} />
                </D>
              }
              <D className="row aC hide unhide-md">
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => this.setState({ menuOpenSM: true })}>
                  <MenuOutlined />
                </IconButton>
              </D>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {!!this.state.view.title && this.state.view.title}
              </Typography>
              <D className="row aC">
                <D className="col aC hide-md">
                  <Typography variant="h6" component="div">{this.props.user.name}</Typography>
                </D>
                <IconButton onClick={e => this.setState({ profileMenu: e.currentTarget })} aria-expanded={!!this.state.profileMenu ? 'true' : undefined}
                  size="small" sx={{ ml: 2 }} aria-controls={!!this.state.profileMenu ? 'account-menu' : undefined}>
                  {!!this.props.user.photo ?
                    <img alt="" src={C.baseURL + 'pic/' + this.props.user.photo} style={{ width: '32px', height: '32px' }} /> :
                    <Avatar sx={{ width: 32, height: 32 }}>{!!this.props.user.name && this.props.user.name[0]}</Avatar>}
                </IconButton>
              </D>
              <Menu
                anchorEl={this.state.profileMenu}
                id="account-menu"
                open={!!this.state.profileMenu}
                onClose={() => this.setState({ profileMenu: null })}
                onClick={() => this.setState({ profileMenu: null })}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1, },
                    '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0, }
                  }
                }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <D className="col aC" style={{ padding: '0.5rem' }}>
                  <AccountCircle fontSize="small" />
                  <Typography variant="h6" component="div">{this.props.user.name}</Typography>
                  <Typography sx={{ fontWeight: 'light', textAlign: 'center' }} variant="subtitle2" component="div">{this.props.user.role}</Typography>
                  <D h="1rem" />
                  <Divider />
                  <Api fontSize="small" />
                  <Typography sx={{ textAlign: 'center' }} variant="h6" component="div">{C.appName}</Typography>
                  <Typography sx={{ fontWeight: 'light', textAlign: 'center' }} variant="subtitle2" component="div">{C.appVersion}</Typography>
                  <D h="0.5rem" />
                  <Divider />
                </D>
                <Link to="/profile" onClick={() => this.changeView('/profile')}><MenuItem><ListItemIcon><Settings fontSize="small" /></ListItemIcon>Preferências</MenuItem></Link>
                <Link to="/" onClick={() => this.props.logout()}><MenuItem><ListItemIcon><Logout fontSize="small" /></ListItemIcon>Sair</MenuItem></Link>
              </Menu>
            </Toolbar>
          </AppBar>
          <D className="row e aF scD">{!!this.state.view.component && React.isValidElement(this.state.view.component) ? React.cloneElement(this.state.view.component, { ...this.props, permission: this.state.view.perm }) : <NoPage />}</D>
        </D>
        {this.state.defaultPage}
      </D >
    )
  }
}
