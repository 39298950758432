import React from 'react'
import Formsy from 'formsy-react'
import {Autocomplete, TextField, Button, IconButton, Table, TableContainer, TableHead, TableBody, Paper, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { C, D, Loading, SearchField, TRow, TCell, TextFieldFormsy } from '../include'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const oicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class EditGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nome: !!this.props.row ? this.props.row.texto : '',
      embacacoes: [],
      embarcacoesID: [], 
      selectEmbacacoes: [],
      embarcacoesName: [],
      grupos: [],
    }
  };
  
  getEmbarcacaoID = (value) => {
     let idArray = []
    this.state.embacacoes.forEach(element => {
      if (value.find(obj => obj === element.nome)) {
        idArray.push(element.id)
      }})
      this.setState({ embarcacoesID: idArray });        
  };
  
 async getSelectEmbarcacoes  () {

    const data =  await  this.props.api.get(`/Grupo/Embarcacoes?selectGroups=${JSON.stringify([this.props.row.id])}`);
    let embarcarcoesSelectNome = [];
    let embarcacoesSelectID = [];
    
    data.map(value => embarcarcoesSelectNome.push(value.nome) &&
    embarcacoesSelectID.push(value.embarcacao_id)
    );
    
    this.setState({embarcacoesID: embarcacoesSelectID})
    return embarcarcoesSelectNome
  };

  componentDidMount = () => {
    this.props.api.get(`/Grupos`).then(res => {
      this.setState({grupos: res})
    });

    this.props.api.get('/embarcacoes').then(response =>{
      let embarcacoesArray = [];
      response.forEach(element => {
        embarcacoesArray.push(element.nome)
      })
      this.setState({embarcacoesName: embarcacoesArray, embacacoes: response})
      }); 
    if (this.props.edit) {
    this.getSelectEmbarcacoes().then((values) => this.setState({selectEmbacacoes: values}))
    };
  };

    componentDidUpdate(prevProps, prevState) {
    if (this.props.row !== prevProps.row) {
      this.getSelectEmbarcacoes()
    }
    };

    

  render() {
    return (
      <Formsy onValidSubmit={() => null} >
        {!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Adicionar {C.groupLabel}</DialogTitle>}
        {!!this.props.edit && <DialogTitle style={{ minWidth: '400px' }}>Editar {C.groupLabel}</DialogTitle>}
        <DialogContent>
          <D h="1rem" />
          <TextFieldFormsy defaultValue={!!this.props.row ? this.props.row.nome : ''} value={this.state.nome} onChange={v => this.setState({ nome: v })} type="text" name="name" label="Nome" variant="outlined" required />
          <Autocomplete
            multiple={true}
            id="checkboxes-tags-demo"
            options={this.state.embarcacoesName}
            value={this.state.selectEmbacacoes}
            getOptionLabel={(options) => options || ''}
            isOptionEqualToValue={(option, value) =>
              option === value
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={oicon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Embarcações" placeholder="" />
            )}
            onChange={(e, value) => {
              this.setState({selectEmbacacoes: value})
              this.getEmbarcacaoID(value)
              }} />
          <D h="2rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            if (!this.props.edit) {
              this.props.api.post('/Grupo', { nome: this.state.nome, embarcacoes: JSON.stringify(this.state.embarcacoesID)}).then(r => {
                this.props.dialogClose()
                this.props.alert('Registro criado com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao criar o registro'))
            } else {
              this.props.api.post('/GrupoUpdate', { grupo_id: this.props.row.id, nome: this.state.nome, embarcacoes: JSON.stringify(this.state.embarcacoesID )}).then(r => {
                this.props.dialogClose()
                this.props.alert('Alterações salvas com sucesso')
              }).catch(e => this.props.alert('Ocorreu um erro ao salvar alterações'))
            }
          }}>Salvar</Button>
        </DialogActions>
      </Formsy >
    )
  }
}
class ConfirmGrupoDelete extends React.Component {
  render() {
    return (
      <>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir:</DialogContentText>
          <D h="1rem" />
          <DialogContentText align="center" variant="h6">{this.props.row.id + ' - ' + this.props.row.nome}</DialogContentText>
          <D h="1rem" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => this.props.dialogClose()}>Cancelar</Button>
          <Button variant="outlined" color="primary" onClick={() => this.props.api.delete(`/Grupo?nome=${this.props.row.nome}` ).then(r => {
            this.props.dialogClose()
            this.props.alert('Registro excluído com sucesso')
          }).catch(e => this.props.alert('Ocorreu um erro ao excluir o registro'))}>Confirmar</Button>
        </DialogActions>
      </>
    )
  }
}
export class Grupo extends React.Component {
  constructor(props) {
    super(props)
    this.updateData = this.updateData.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.state = { selectEmbacacoes: [], searchField: '', dataTable: [], rows: [<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={6}><Loading /></TCell></TRow>] }
  }
  

  componentDidMount = () => this.updateData()
  updateData = () => this.props.api.get('/Grupos').then(r => {
    this.updateTable(r)})
  updateTable = rows => {
    rows = typeof rows === 'object' ? rows : this.state.dataTable
    let res = []
    rows.length > 0 ? rows.forEach(row => {
      res.push(<TRow key={row.id} row={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<EditGroup edit row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Edit />
            </IconButton>
          </TCell>
        }
        {this.props.permission === 'w' &&
          <TCell align="center" component="th">
            <IconButton style={{ color: '#aaa' }} onClick={() => this.props.dialog(<ConfirmGrupoDelete row={row} api={this.props.api} alert={this.props.alert} dialogClose={() => {
              this.updateData()
              this.props.dialogClose()
            }} />)}>
              <Delete />
            </IconButton>
          </TCell>
        }
        <TCell component="th">{row.nome}</TCell>
      </TRow>)
    }) : res.push(<TRow key={Math.random().toString()}><TCell component="th" align="center" colSpan={10}>Nenhum Grupo encontrado</TCell></TRow>)
    this.setState({ dataTable: rows, rows: res })
  }
  render() {
    return (
      <D className="col e aF scD" style={{ padding: '2rem' }}>
        {this.props.permission === 'w' &&
          <>
            <D className="row w aF">
              <SearchField value={this.state.searchField} onChange={v => this.setState({ searchField: v })} />
              <D e /><D size="2rem" />
              <Button variant="contained" color="primary" onClick={() => this.props.dialog(<EditGroup api={this.props.api} alert={this.props.alert} dialogClose={() => {
                this.updateData()
                this.props.dialogClose()
              }} />)}><Add />{C.groupLabel}</Button>
            </D>
            <D h="1rem" />
          </>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TRow sx={{ height: '3rem', background: '#ccc' }}>
                  {this.props.permission === 'w' && <TCell align="center">Editar</TCell>}
                  {this.props.permission === 'w' && <TCell align="center">Excluir</TCell>}
                  <TCell width="100%">{C.groupLabel}</TCell>
                </TRow>
              </TableHead>
              <TableBody>{!!this.state.rows && this.state.rows.length > 0 && this.state.rows.map(r => {
                if (!!this.state.searchField) {
                  let sc = !!r.props.row ? Object.values(r.props.row).map(v => String(v)).join() : ''
                  if (sc.toLowerCase().replace(' ', '').includes(this.state.searchField.toLowerCase().replace(' ', ''))) {
                    return r
                  } else { return null }
                } else { return r }
              })}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </D>
    )
  }
}
